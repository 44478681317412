import { Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { selectActivateAccount, setActivateAccount } from '../../../Slice/modalVisibilitySlice';
import './Modal.css';
import { useNavigate } from 'react-router-dom';
import {Url} from '../../../constants/global';


function ResendActivateAccountLink() {
  const show = useSelector(selectActivateAccount);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  const handleClose = () => {
    setActivateAccount(false);
    navigate("/");
  };

  dispatch(setActivateAccount(true)); //set visibility true

  function sendMail(){
    const fetchPromise = fetch(Url +'/oliver/resend-confirmation-mail', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: token})
    });
    
    fetchPromise
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      } else {
        handleClose();
      }
    })
    .catch((error) => {
      console.error(`Could not get products: ${error}`);
    });

  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" className='modal'>
        <Modal.Header>
          <Modal.Title className='title'>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path opacity=".87" d="M0 0h24v24H0z" /><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z" fill="#5ABEBA" /></g></svg>
            Activate your account</Modal.Title>
          <Button onClick={handleClose} id='close'>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z" /><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <p>Your activation link has expired.<br/>Please request a new one using the button below.</p>
            </Row>
            <Row> 
              <Button onClick={sendMail} className="mb-3 secondary-button " type='button'>Request activation link</Button>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ResendActivateAccountLink;