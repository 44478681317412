import { createSlice } from '@reduxjs/toolkit';

const initialState = { value: [], count: [0, 0], placeholder: 'Search by phenotype', showHint: false, phenotypeHints:[] }; //count [valid, invalid]

export const phenotypeSlice = createSlice({
    name: 'phenotype',
    initialState,
    reducers: {
        setPhenotype: (state, action) => {
            state.value.push(action.payload);
            switch (action.payload.code) {
                case 0:
                    state.count[0]++;
                    break;
                case 2:
                    state.count[1]++;
                    break;
                default:
                    break;
            }
        },
        deletePhenotype: (state, action) => {
            state.value.splice(action.payload.index, 1);
            switch (action.payload.code) {
                case 0:
                    state.count[0]--;
                    break;
                case 2:
                    state.count[1]--;
                    break;
                default:
                    break;
            }
        },
        setPlaceholderPh: (state, action) => {
            state.placeholder = action.payload;
        },
        setShowHint: (state, action) => {
            state.showHint = action.payload;
        },
        setPhenotypeHints: (state, action) => {
            state.phenotypeHints = action.payload;
        },
        resetInitialValuePh: (state) => {
            state.value = [];
            state.count = [0, 0];
            state.placeholder = 'Search by phenotype';
            state.phenotypeHints= [];
        }
    }
});

export const { setPhenotype, deletePhenotype, resetInitialValuePh, setPlaceholderPh, setPhenotypeHints, setShowHint } = phenotypeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectPhenotype = (state) => state.phenotype.value;
export const selectCount = (state) => state.phenotype.count;
export const selectPlaceholder = (state) => state.phenotype.placeholder;
export const selectPhenotypeHints = (state) => state.phenotype.phenotypeHints;
export const selectShowHint = (state) => state.phenotype.showHint;

export default phenotypeSlice.reducer;