import { Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { selectPrivacyPolicy, setPrivacyPolicy } from '../../../../Slice/modalVisibilitySlice';
import '../../../headerBar/Modal/Modal.css'
import './PrivacyPolicy.css';

function PrivacyPolicy() {
    const show = useSelector(selectPrivacyPolicy);
    const dispatch= useDispatch();

    const handleClose = () => {
        dispatch(setPrivacyPolicy(false));
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" dialogClassName='modal-dim'>
                <Modal.Header>
                    <Modal.Title className='title'>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#344D66" d="M480-80q-139-35-229.5-159.5T160-516v-244l320-120 320 120v244q0 152-90.5 276.5T480-80Zm0-84q97-30 162-118.5T718-480H480v-315l-240 90v207q0 7 2 18h238v316Z"/></svg>Privacy Policy</Modal.Title>
                    <Button onClick={handleClose} id='close'>
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z" /><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
                    </Button>
                </Modal.Header>
                <Modal.Body className="custom-modal" id="privacy-policy-modal">
                    <Container >
                        <Row>
                            <p>
                                The European Data Protection Regulation n. 679/2016 (also known as “GDPR”), is about the protection of natural persons with regards to the processing of personal data and concerning the free movement of such data. Pursuant to this Regulation and the Italian law, we share the necessary information for you to understand how we process your data collected via our Platform. <br/>
                            In case you have any other questions, contact our Data Protection Officer (<a style={{textDecoration:"none"}} href="mailto:privacy@engenome.com">privacy@engenome.com</a>).
                                </p><div>We are committed to protecting you and being transparent about our process.<br/>
                                enGenome srl is the data controller (hereinafter “enGenome” and/or "Data Controller") of Users personal data (hereinafter also "Data subject") that use our Platform, including any subdomains (hereinafter "Site").
                                Through the Site, enGenome provides the Services described in these Terms and collects the following Data:<br/>
                                <ul className = "ul-custom">
                                    <li>First and last name</li>
                                    <li>IP address, device and browser information</li>
                                    <li>Cookie and pixel (in accordance to our cookie policy)</li>
                                    <li>Company or Institution related to the user</li>
                                    <li>Email address and the telephone number</li>
                                </ul>
                                </div><div>enGenome does not need to process further data than that described above. The additional information that the Data Subject provides through the existing messaging form in the Site will be processed in accordance with this Privacy Policy, these Terms and the provisions of the European and national legislation (hereinafter this data will be jointly considered as "Data").<br/>
                                The purposes of the Data processing are:
                                <ul className = "ul-custom">
                                    <li>Allow access to the Site</li>
                                    <li>Answer requests for Information about the Services</li>
                                    <li>Conclude contracts related to the Services and fulfill contractual obligations with the Users (contractual purposes)</li>
                                    <li>For the promotion of the Services through traditional and remote communication tools (marketing purposes, e.g. by Newsletter)</li>
                                </ul></div><p>
                                In this case, the Data will be kept for a maximum period of 24 months from the consent of the Data Subject.
                                The Data is processed with manual or IT tools, also through automated tools, suitable to guarantee its security, confidentiality and to avoid unauthorized access.<br/>
                                Data will be communicated at any time to the Data Controller, by means of a specific request to be sent using the contact information indicated in this Privacy Policy.
                                In any case, the processing of data is carried out in accordance with the national and supranational provisions in force in the field (Legislative Decree 196/2003, as amended and supplemented by Legislative Decree 101/2018, and EU Regulation 679/2016).
                                The Data Subject will have the right to obtain from the data controller a copy of the Data held abroad and to obtain information about the place where such data is stored making an express request to be sent using the contacts indicated in this document.
                                <br/></p><div>By contacting the Data Controller and the DPO (using the above mentioned e-mail address) or the other contact information above, you can ask enGenome at any time:
                                <ul className = "ul-custom">
                                    <li>access to the Data concerning you, the rectification of inaccurate Data, the integration of incomplete Data, their deletion (right to be forgotten), the limitation of processing in the cases provided for art. 18 GDPR;
                                    </li>
                                    <li>to receive in a structured format, common use and readable by automatic device the Data concerning you in the cases provided for by art. 20 GDPR; and, if technically feasible, to transmit such data to another data controller without hindrance;
                                    </li>
                                    <li>
                                        to withdraw the consent given at any time; as well as oppose at any time the processing of Data pursuant to art. 21 GDPR, giving evidence of the reasons justifying the opposition;
                                    </li>
                                    <li>
                                        any other request for clarification regarding the processing of Data carried out by the Data Controller.
                                    </li>
                                </ul>
                                </div><p>Finally, we remind you that it is always possible to make a complaint to the competent Supervisory Authority (Guarantor for the Protection of Personal Data) pursuant to art. 77 of the GDPR, if it considers that the processing carried out by the Data Controller is contrary to the actual legislation.
                                <br/>
                                The Data Controller may make changes and/or additions to this Privacy Policy, also as a result of changes in the applicable legislation. You can view the text of the Privacy Policy constantly updated at our Site in the Privacy Policy section or make an explicit request by contacting the Data Controller or the DPO directly using the contact information indicated above.
                            </p>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default PrivacyPolicy;