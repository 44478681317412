import { createSlice } from '@reduxjs/toolkit';

const initialState={value:[{ pass:'', confirm:'', old:''}]};

export const passwordSlice = createSlice({
    name: 'password',
    initialState,
    reducers: {
        setPassword: (state, action) => {
            state.value= action.payload;
        },
        setPasswordInit: (state) =>{
            state.value = initialState.value;
        }
    }
});

export const { setPassword, setPasswordInit } = passwordSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectPassword = (state) => state.password.value;

export default passwordSlice.reducer;