import { useDispatch } from "react-redux";
import { deleteGene } from '../../Slice/geneSlice';
import { deletePhenotype } from '../../Slice/phenotypeSlice';

function DeleteBubble(props) {
    const dispatch= useDispatch();

    function close() {
        if(props.type==='phenotype'){
        dispatch(deletePhenotype({index:props.index,code:props.value.code}));
        } else if(props.type==='gene'){
        dispatch(deleteGene({index:props.index,code:props.value.code}));
        }
    }

    return (
        <p className="close" onClick={close}>
            <svg width="18" height="18" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                    <path d="M0 0h24v24H0z" />
                    <path fill="#efefef" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </g>
            </svg></p>
    );
}

export default DeleteBubble;