import React from 'react';
import { useRef, useState} from "react";
import { useSelector } from "react-redux";
import { selectCombinations, selectIndexSelectedRow } from "../../../../../Slice/resultsSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import './geneInfo.css'
import {Overlay} from "react-bootstrap";

function GenesInfo() {
    const combinations = useSelector(selectCombinations);
    const index = useSelector(selectIndexSelectedRow);
    const [showInfo, setShowInfo] = useState(combinations[index].combinationMembers.map((comb) => {
        return { showTrans: true, showTransInfo: [{ visible: true }].concat(Array(comb.variantList[0].transcriptList.length - 1).fill({ visible: false })) }
    }));
    const nGenes=2;
    const [showMore, setShowMore]= useState(Array(nGenes).fill(false));
    let transcript = [{trans:[]}, {trans:[]}];
    const [isYellow, setIsYellow] = useState({});
    const [isShown, setIsShown] = useState({});
    const ref = useRef([]);
    let info;
    const handleClick = (name) => {
        setIsYellow( {[name] : true});
        setIsShown({[name]:true});
        setTimeout(() => {
            setIsYellow( {[name] : false});
            setTimeout(() => {
                setIsShown({[name]:false});
            }, 500);
        }, 500);
    }

    const genes = combinations[index].combinationMembers.map((gene, i) => {
        return <React.Fragment key={gene+i} ><p className='gene-result-info' key={gene+i} onClick={() => geneClick(i)}>{showInfo[i].showTrans === true ? <span className="plus-minus">-</span> : <span className="plus-minus">+</span>} <a onClick={(e) => { e.stopPropagation() }} href={`https://www.ncbi.nlm.nih.gov/gene/?term=${gene.gene}`} target='_blank' rel='noreferrer'>{gene.gene}</a></p>
            {showInfo[i].showTrans === true ?  showGeneInfo(gene, i) : null}
            {transcript[i].trans.length===1 ? null : showMore[i]===false && showInfo[i].showTrans === true ? <p className="showMore geneShowMore" onClick={()=>{updateShowMore(i)}}>Show more...</p> : transcript[i].trans.slice(1)}
        </React.Fragment>
    })

    function showGeneInfo(gene, i) {
        let ind=1;
        return gene.variantList[0].transcriptList.map((trans, j) => {
            info = <React.Fragment key={trans+j}><p key={trans+j} className='transcript-info' onClick={() => transClick(j, i)}>
                {showInfo[i].showTransInfo[j].visible === true ? <span className="plus-minus">-</span> : <span className="plus-minus">+</span>}
                <a className="copyTid"  style={{backgroundColor: isYellow[trans.tid] ? "yellow" : null}}>{trans.tid}</a>
                <FontAwesomeIcon ref={el => ref.current[i === 0 ? i+j : i+j+ combinations[index].combinationMembers[i-1].variantList[0].transcriptList.length] = (el)}  title='copy' className='copy-icon' icon={faCopy} onClick={ (e) => { e.stopPropagation(); navigator.clipboard.writeText(trans.tid); handleClick(trans.tid);}}/>
                <Overlay target={ref.current[i === 0 ? i+j : i+j+ combinations[index].combinationMembers[i-1].variantList[0].transcriptList.length]} show={isShown[trans.tid] ? isShown[trans.tid] : false} placement = 'top'>
                    {({
                          placement: _placement,
                          arrowProps: _arrowProps,
                          show: _show,
                          popper: _popper,
                          hasDoneInitialMeasure: _hasDoneInitialMeasure,
                          ...props
                      }) => (
                        <div className='tooltip-copy' {...props}>Copied!</div>
                    )}
                </Overlay>
                {trans.select === true ? <span className="select">Select</span> : null}
            </p>
                {showInfo[i].showTransInfo[j].visible === true ? showTranscriptInfo(gene, j) : null}
            </React.Fragment>;
            if (trans.select === true) {
                transcript[i].trans[0] = info;
                return transcript[i].trans[0];
            } else {
                transcript[i].trans[ind]=info;
                ind++;
                return null;
            }
        })
    }

    function showTranscriptInfo(gene, j) {
        return (
            <div className="transcript-info-panel">
                <table className="info-table">
                    <tbody>
                        {gene.variantList.map((variant, i) => {
                            return <tr key={variant+i}>
                                <td className="tdhgvs">
                                    <span className="spanhgvs"> <p className="infoTable-label">HGVS (coding):</p><p className="phgvs">{variant.transcriptList[j].hgvsc !== null ? variant.transcriptList[j].hgvsc : 'NA'}</p></span>
                                </td>
                                <td className="tdhgvs">
                                    <span className="spanhgvs"> <p className="infoTable-label">HGVS (protein):</p><p className="phgvs">{variant.transcriptList[j].hgvsp !== null ? variant.transcriptList[j].hgvsp : 'NA'}</p></span>
                                </td>
                                <td className="tdhgvs">
                                    <span className="spanhgvs"> <p className="infoTable-label">Effect:</p><p className="phgvs">{variant.transcriptList[j].effect !== null ? variant.transcriptList[j].effect : 'NA'}</p></span>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>);
    }

    function geneClick(i) {
        setShowInfo(showInfo.map((state, j) => {
            if (i === j) {
                return { ...state, showTrans: !state.showTrans };
            } else {
                return { ...state };
            }
        }));
    }

    function transClick(j, i) {
        setShowInfo(showInfo.map((state1, ind) => {
            if (ind === i) {
                return {
                    ...state1, showTransInfo: state1.showTransInfo.map((state, k) => {
                        if (j === k) {
                            return { ...state, visible: !state.visible };
                        } else {
                            return { ...state };
                        }
                    })
                };
            } else {
                return { ...state1 };
            }
        }))
    }

    function updateShowMore(i){
        setShowMore(showMore.map((state, j)=>{
            if(i===j){
                return true;
            } else {
                return state;
            }
        }))
    }

    return (
        <div className="info-panel">
            <p className="info-subtitle">Number of Genes: {combinations[index].combinationMembers.length}</p>
            {genes}
        </div>
    )
}

export default GenesInfo;