import '../Search.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectSearch, setSearch, selectUpdateFocus } from '../../../Slice/searchBarSlice';
import { validateGenomicSearch, validateHgvsSearch, variantSearch } from '../validationSearchFunction/variantValidation';
import { resetWarning, setWarning } from '../../../Slice/warningSlice';
import { selectType, changeTypeAssembly } from '../../../Slice/typeSlice';
import { setTimer } from '../../../Slice/timerSlice';
import { useRef, useState, useEffect } from 'react';
import { setCombinations, setShowResults } from '../../../Slice/resultsSlice';
import {setLoader} from "../../../Slice/loaderSlice";

function VariantSearch() {
    const searchText = useSelector(selectSearch);
    const dispatch = useDispatch();
    const type = useSelector(selectType);
    const timerRef = useRef();
    const inputRef = useRef(null);
    const [isPaste, setPaste] = useState(false);
    const exampleFocus = useSelector(selectUpdateFocus);

    useEffect(()=>{
        inputRef.current.focus();
    }, [exampleFocus]);

    const timerFunction = () => {
        timerRef.current = setTimeout(() => {
            validateVariant(searchText);
        }, 5000)
        dispatch(setTimer(timerRef.current));
    }

    const clearTimerFunction = () => {
        clearTimeout(timerRef.current);
    }

    function handlechange(e) {
        dispatch(setSearch(e.target.value));
        dispatch(resetWarning());
        if (isPaste) {
            validateVariant(e.target.value);
        }
    }

    function keyup(e) {
        if (!isPaste) {
            clearTimerFunction();
            if (e.key !== 'Enter' && searchText.replace(/\s+/g, '') !== '') {
                timerFunction();
            }
        }
    }

    async function keydown(e) {
        clearTimerFunction();
        setPaste(false);
        if (e.key === 'Enter') {
            dispatch(setLoader(true));
            const variant = await variantSearch(searchText, type.assembly);
            dispatch(setLoader(false));
            if (variant.length > 2) {
                let geneName;
                switch (variant[2].code) {
                    case 1:
                        geneName = searchText.split(':');
                        dispatch(setWarning({ show: true, value: geneName[0] + ' gene will be searched using its synonym ' + variant[2].gene }));
                    case 0:
                        dispatch(setShowResults(true));
                        dispatch(setCombinations(variant[1]));
                        break;
                    case 2:
                        geneName = searchText.split(':');
                        dispatch(setWarning({ show: true, value: geneName[0] + ' gene has not been found' }));
                        break;
                }
            } else if (variant[0]) {
                dispatch(setShowResults(true));
                dispatch(setCombinations(variant[1]));
            } else if (!variant[0] && searchText.replace(/\s+/g, '') !== '') {
                dispatch(setWarning({ show: true, value: 'Invalid search format' }));
            }
        }
    }

    function paste() {
        clearTimerFunction();
        setPaste(true);
    }

    async function validateVariant(searchText) {
        const genomic = validateGenomicSearch(searchText);
        const hgvs = await validateHgvsSearch(searchText);
        if (genomic[0] || hgvs[0]) {
            dispatch(resetWarning());
        } else {
            dispatch(setWarning({ show: true, value: 'Invalid search format' }));
        }
    }

    return (
        <>
            <input type="text" name="input-search" className='inputSearchVar' placeholder="Search by genomic variant" value={searchText} onChange={handlechange} onKeyUp={(e) => keyup(e)} onKeyDown={keydown} onPaste={paste} ref={inputRef} autoComplete="off"/>
            <select name='typeAssembly' className='typeAssembly' value={type.assembly} onChange={(e) => dispatch(changeTypeAssembly(e.target.value))}>
                <option value='GRCh37'>GRCh37</option>
                <option value='GRCh38'>GRCh38</option>
            </select>
        </>
    );
}

export default VariantSearch;