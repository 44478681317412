import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './FooterBar.css';
import {setPrivacyPolicy, setTermsOfUse} from "../../Slice/modalVisibilitySlice";
import { useDispatch } from 'react-redux';
import PrivacyPolicy from "./Modal/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./Modal/TermsOfUse/TermsOfUse";

function FooterBar() {
    const dispatch = useDispatch();
    function privacyClick(){
        dispatch(setPrivacyPolicy(true));
    }

    function termsOfUseClick(){
        dispatch(setTermsOfUse(true));
    }

    return (
        <Row className='footerBar'>
            <Col className='term' lg={1} md={2} sm={2} onClick={termsOfUseClick}>
                <div>Terms of Use</div>
            </Col>
            <Col className='privacy' lg={1} md={2} sm={2} onClick={privacyClick} >
                <div>Privacy Policy</div>
            </Col>
            <Col className='signature' lg={8} md={4} sm={4}>
                <div>2023 © enGenome srl - Oliver version 1.0</div>
            </Col>
            <PrivacyPolicy/>
            <TermsOfUse/>
        </Row>
    );
}

export default FooterBar;