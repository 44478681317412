import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIndexSelectedRow, setButtonResults, setIndexSelectedRow } from '../../../../Slice/resultsSlice';
import DigenicInfo from '../Information/DigenicInfo';
import Accordion from 'react-bootstrap/Accordion';
import Zygosity from '../Zygosity';
import Mechanism from '../Mechanism';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useRef, useEffect, useState } from 'react';

function Combination(props) {
    let variantRow = [];
    let row = [];
    let hpo = [];
    let colInd = 0;
    const dispatch = useDispatch();
    const showResultInfoIndex = useSelector(selectIndexSelectedRow);
    const predict = props.value.predictedDtype.split('/');
    const legend = {
        TD: 'True Digenic',
        DMD: 'Dual Molecular Diagnosis',
        CO: 'Composite',
        NA: 'Not Available'
    }
    const prediction = predict.length === 1 ? legend[predict[0]] : `${legend[predict[0]]} / ${legend[predict[1]]}`;
    const ref = useRef([]);
    const [isOverflown, setIsOverflown] = useState(Array(4).fill(false));
    const [width, setWidth] = useState(window.innerWidth); // check width size of the window
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        let element;
        let count1 = 0;
        setIsOverflown(prevState => prevState.map((item, j) => {
            element = ref.current[count1];
            if (element !== undefined && element !== null) {
                if (j === count1) {
                    const varia = element.offsetWidth < element.scrollWidth;
                    count1++;
                    return varia;
                } else {
                    count1++;
                    return item;
                }
            }
            return null;
        }));

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };

    }, [props.list, width]);

    for (let i = 0; i < props.value.combinationMembers.length; i++) {
        let count = props.value.combinationMembers[0].variantList.length - 1;
        row[i] = <React.Fragment key={colInd++}><td key={colInd++} className='gene-col' rowSpan={2}>{props.value.combinationMembers[i].gene}</td>
            {props.value.combinationMembers[i].variantList.map((variant, j) => {
                let hgvs = `${variant.transcriptList[0].hgvsc !== null ? variant.transcriptList[0].hgvsc : ''} ${variant.transcriptList[0].hgvsp !== null ? variant.transcriptList[0].hgvsp : ''}`;
                let cols = <React.Fragment key={colInd++}><td key={colInd++} className='zygosity-col' rowSpan={props.value.combinationMembers[i].variantList.length === 2 ? 1 : 2}>
                    <Zygosity variant={variant.gt} index={props.index} />
                </td>
                    <td key={colInd++} className='hgvs-col' rowSpan={props.value.combinationMembers[i].variantList.length === 2 ? 1 : 2}>
                        <OverlayTrigger placement='right' overlay={isOverflown[i === 0 ? i + j : i + j + count] ? <Tooltip className='hgvs-tooltip'><div className='tool'>{hgvs}</div></Tooltip> : <></>}>
                            <span key={colInd++} ref={el => ref.current[i === 0 ? i + j : i + j + count] = el}>{hgvs}</span>
                        </OverlayTrigger>
                    </td>
                </React.Fragment>;
                if (j === 0) {
                    return cols;
                } else {
                    variantRow[i] = cols;
                    return null;
                }
            })}
        </React.Fragment>;
    }

    if(props.value.hpos != null && props.value.hpos.length>0){
    for (let i = 0; i < props.value.hpos.length; i++) {
        hpo[i] = <span className='hpo-list' key={colInd++} style={props.value.hpos[i].code === 2 ? { fontWeight: 'bold' } : props.value.hpos[i].code === 1 ? { fontWeight: '500' } : { fontWeight: 'normal' }}>{props.value.hpos[i].name} {'\n'}</span>
        if (i === 2) {
            if (props.value.hpos.length > 3) {
                hpo[i + 1] = <span key={colInd++} onClick={(e) => { openPhenotypes(e) }}>...</span>
            }
            break;
        }
    }
    }else{
        hpo[0] =  <span className='hpo-list' key='-1'> NA </span>
    }

    function showInfo() {
        if (props.index !== showResultInfoIndex) {
            dispatch(setIndexSelectedRow(props.index));
        } else {
            dispatch(setIndexSelectedRow(-1));
        }
        dispatch(setButtonResults('general'));
    }

    function openPhenotypes(e) {
        e.stopPropagation();
        showInfo();
        dispatch(setButtonResults('phenotypes'));
    }

    return (
        <Accordion.Item eventKey={props.index} key={props.index}>
            <Accordion.Header onClick={showInfo}>
                <table className='digenic-table-body'>
                    <tbody>
                        <tr className='digenic-table-row' key='first row'>
                            {row}
                            <td key={colInd++} className='predict-col-img' rowSpan={2}>
                                <Mechanism index={props.index} />
                            </td>
                            <td key={colInd++} className='predict-col' rowSpan={2}>{prediction}</td>
                            <td key={colInd++} className='hpo-col' rowSpan={2}> {hpo} </td>
                        </tr>
                        <tr className='digenic-table-variantRow' key='second row'>{variantRow.length !== 0 ? variantRow : null}</tr>
                    </tbody>
                </table>
            </Accordion.Header>
            <Accordion.Body>{showResultInfoIndex === props.index ? <DigenicInfo /> : null}</Accordion.Body>
        </Accordion.Item>
    );
}

export default Combination;