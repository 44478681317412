import trueDigenic from '../../../Images/trueDigenic.png';
import composite from '../../../Images/composite.png';
import dualMolecular from '../../../Images/dualMolecular.png';
import nAMechanism from '../../../Images/NAMechanism.png';
import { useSelector } from "react-redux";
import { selectCombinations } from "../../../Slice/resultsSlice";

function Mechanism(props) {
    const combinations = useSelector(selectCombinations);
    const predict = combinations[props.index].predictedDtype.split('/');
    let mechanism;

    switch (predict[0]) {
        case 'TD':
            mechanism = trueDigenic; //da cambiare in caso di hover e in caso di selezione, da vedere
            break;
        case 'DMD':
            mechanism = dualMolecular;
            break;
        case 'CO':
            mechanism = composite;
            break;
        case 'NA':
            mechanism = nAMechanism;
            break;
        default:
            break;
    }

    return (
        <>{<img title={predict[0]} predict={predict[0]} src={mechanism} alt={predict[0]}/>}</>
    );
}

export default Mechanism;