import { useState } from 'react';
import './HelpCenter.css';
import PageHelperEntry from "./PageHelperEntry";

function SearchingHelper() {
    const [open, setOpen] = useState(true);
    const [rotate, setRotate] = useState(null);
    let searchByVariant = {title:'By Variant', content: comp1()};
    let searchByGene = {title:'By Gene', content: comp2()};
    let searchByPhenotype = {title:'By Phenotype', content: comp3()};

    function comp1(){
        return (
        <div className={open ? 'show' : 'notshow'}>
            <b>Description</b>: Search for combinations involving specific genetic variants. Select the "Variant" option in the drop-down menu next to the search bar.<br/>
            <b>Accepted Formats</b>: Genomic coordinates or HGVS. Both GRch37 and GRCh38 assemblies are supported.<br/>
            <br/>
            <b>Examples</b><br/>
            <table className='tabledim'>
                <thead><tr><td><b>Genomic Coordinates</b>
                </td><td><b>HGVS </b>
                </td></tr></thead>
                <tbody>
                    <tr>
                        <td className='td-dimension'>
                            <ul style={{listStyle:'none', paddingLeft:'0px'}}>
                                <li>11 66523788 A T</li>
                                <li>11 66291259 A T</li>
                                <li>4 122743329 G -</li>
                                <li>4 123664484 G -</li>
                                <li>7 150947383 - CCGAC</li>
                                <li>7 150644471 - CCGAC</li>
                            </ul></td><td className='td-dimension td-second'>
                            <ul style={{listStyle:'none', paddingLeft:'0px'}}>
                                <li>NM_024649.5:c.1016A&gt;T</li>
                                <li>NM_024649.5:p.His339Leu</li>
                                <li>BBS1:c.1016A&gt;T</li>
                                <li>BBS1:p.His339Leu</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br/>
            <b>Results</b>: Oliver combinations related to the searched variants will be displayed.
        </div>);
    }

    function comp2(){
        return (
        <div className={open ? 'show' : 'notshow'}>
            <b>Description</b>: Search for combinations involving specific genes.<br/>
            <b>Input</b>: Users can enter or paste up to 2 valid (or synonym) genes.<br/>
            <b>Accepted Formats</b>: The platform validates the gene symbol typed or pasted in the search bar. The searched gene can be:<br/>
            <ul>
                <li>In a valid format</li>
                <li>In an invalid format</li>
                <li>Searched as a synonym</li>
            </ul><br/>
            <b>Examples</b><br/>
            BBS1<br/>
            BBS12<br/>
            C4orf24(Synonym)<br/><br/>
            <b>Results</b>: Oliver combinations related to the searched genes will be displayed.<br/>
            Invalid genes will not be included in the search results.<br/>
            This functionality is enabled for registered users only.
            Registration is free of charge.
        </div>);
    }

    function comp3(){
        return (
        <div className={open ? 'show' : 'notshow'}>
            <b>Description</b>: Search for combinations related to specific phenotypes.<br/>
            <b>Input</b>: Users can enter or copy/paste up to 5 phenotypes as HPO terms.<br/>
            <b>Accepted Formats</b>: Both free text descriptions and HPO codes. Free text will trigger a search through the HPO ontology.<br/>
            <br/>
            <b>Examples</b><br/>
            HP:0001738<br/><br/>
            <b>Results</b>: Combinations associated with the searched HPO terms will be displayed.<br/>
            This functionality is enabled for registered users only.
            Registration is free of charge.
        </div>);
    }

    return (<><div>
        <div  className='titleHelpCenter' onClick={() => {setOpen(!open);
            if(rotate !==null) {
                setRotate(!rotate);
            }else{
                setRotate(true);
            }
        }} > Searching for Combinations
            <svg className={rotate !== null ? (rotate ? 'svgClickOpen' : ''): ''} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#344d66"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87" /><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" /></svg>
        </div>

        <div className={open ? 'show' : 'notshow'}>
            <ul style={{listStyle:'none'}}>
                <li><PageHelperEntry props={searchByVariant} /></li>
                <hr/>
                <li><PageHelperEntry props={searchByGene} /></li>
                <hr/>
                <li><PageHelperEntry props={searchByPhenotype} /></li>
            </ul>
        </div>
    </div></>);

}

export default SearchingHelper;