import { createSlice } from '@reduxjs/toolkit';

const initialState={value: false}; //da mettere a false 

// visibility of the modal
export const logSlice = createSlice({
    name: 'log',
    initialState,
    reducers: {
        setLog: (state, action) => {
            state.value= action.payload;
        },
    }
});

export const {setLog} = logSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectLog = (state) => state.log.value;

export default logSlice.reducer;