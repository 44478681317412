import { createSlice } from '@reduxjs/toolkit';

const initialState = { value: { show: false, value: '' } };

export const warningSlice = createSlice({
    name: 'warning',
    initialState,
    reducers: {
        setWarning: (state, action) => {
            state.value = action.payload;
        },
        resetWarning: (state) => {
            state.value = initialState.value;
        }
    }
});

export const { setWarning, resetWarning } = warningSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectWarning = (state) => state.warning.value;

export default warningSlice.reducer;