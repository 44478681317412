import { useState } from 'react';
import './HelpCenter.css';
import PageHelperEntry from "./PageHelperEntry";
import image from '../../../Images/login-screen.png';
import signin from '../../../Images/sign-in-screen.png';
import signup from '../../../Images/sign-up-screen.png';
import resetPass from '../../../Images/reset-password-screen.png';

function RegistrationHelper() {
    const [open, setOpen] = useState(true);
    const [rotate, setRotate] = useState(null);
    let userRegistration = {title:'User Registration', content: comp1()};
    let userLogin = {title:'User Login', content: comp2()};
    let userPassRetrieval = {title:'User Password Retrieval', content: comp3()};

    function comp1(){
        return (
        <>
            <div className={open ? 'show' : 'notshow'}>
                Navigate to the Oliver platform homepage by clicking on the Oliver Logo in the top left corner.<br/>
                Click on the <img className='img-button img-transform' style={{marginLeft: '2px', marginRight: '2px'}} src={image} alt='Login'/> button at the top right corner and then the “Sign Up” link at the bottom. You will be redirected to the registration form.<br/>
                Fill in the required fields, including your full name, email address, username, country, organization and password.<br/>
                Read and accept the Terms of Use, the Privacy Policy and the newsletter susbscription by checking the respective boxes. Click the <img className='img-button' src={signup} alt='Sing up'/> button.<br/>
                A confirmation email will be sent to the provided email address.<br/>
                Click on the verification link in the email to activate your account.<br/>
            </div>
        <div className='hover_img'>
        </div></>);
    }

    function comp2(){
        return (
        <div className={open ? 'show' : 'notshow'}>
            Navigate to the Oliver platform homepage by clicking on the Oliver Logo in the top left corner.<br/>
            Click on the <img className='img-button img-transform' style={{marginLeft: '2px', marginRight: '2px'}} src={image} alt='Login'/> button at the top right corner.<br/>
            Enter your registered username or email and password. Click the <img className='img-button' src={signin} alt='Sign in'/> button. <br/>If entered correctly, you will be granted access to your account and redirected to the dashboard or main page.
        </div>);
    }

    function comp3(){
        return (
        <div className={open ? 'show' : 'notshow'}>
            If you forget your password, click on the "Forgot your password?" link on the login page.<br/>
            You will be prompted to enter the email address associated with your account.<br/>
            Click on the <img className='img-button' src={resetPass} alt='Reset password'/> button. <br/> An email will be sent to the provided address with a link to reset your password (if the mail is associated with a valid account). Click on the link in the email, and you will be redirected to the page where you can set a new password. After resetting, use the new password to log in.
        </div>);
    }

    return (<><div>
            <h6  className='titleHelpCenter' onClick={() => {setOpen(!open);
                if(rotate !==null) {
                    setRotate(!rotate);
                }else{
                    setRotate(true);
                }
            }} >Registration
                <svg className={rotate !== null ? (rotate ? 'svgClickOpen' : ''): ''} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#344d66"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87" /><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" /></svg>
            </h6>

        <div className={open ? 'show' : 'notshow'}>
                <ul style={{listStyle:'none'}}>
                    <li><PageHelperEntry props={userRegistration} /></li>
                    <hr/>
                    <li><PageHelperEntry props={userLogin} /></li>
                    <hr/>
                    <li><PageHelperEntry props={userPassRetrieval} /></li>
                </ul>
            </div>
    </div></>);
}

export default RegistrationHelper;