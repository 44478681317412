import Gene from './Gene';
import { useSelector } from 'react-redux';
import { selectGene } from '../../../Slice/geneSlice';

function GeneList() {
    const geneList = [];
    const genes = useSelector(selectGene);

    if (genes.length !== 0) {
        genes.map((gene, i) => {
            geneList.push(<Gene value={gene} key={i} index={i} />);
            return null;
        });
    }

    return (
        <span className='geneList'>{geneList}</span>
    );
}

export default GeneList;