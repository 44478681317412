import './exampleButton.css';
import { useSelector, useDispatch } from 'react-redux';
import { selectType } from '../../../Slice/typeSlice';
import { setSearch, triggerInputPaste, selectTriggerInput, updateFocus, selectUpdateFocus } from '../../../Slice/searchBarSlice';
import { selectExample, setShowExample } from '../../../Slice/modalVisibilitySlice';
import { resetWarning } from '../../../Slice/warningSlice';
import { setClearTimer } from '../../../Slice/timerSlice';
import { selectGene } from '../../../Slice/geneSlice';
import { selectPhenotype } from '../../../Slice/phenotypeSlice';

function ExampleButton() {
    const visible = useSelector(selectExample);
    const type = useSelector(selectType);
    const geneInfo = useSelector(selectGene);
    const phenotypeInfo = useSelector(selectPhenotype);
    const dispatch = useDispatch();
    const nGene = 2; //max number of gene that we can search
    const nPhenotype = 5;
    const triggerRef= useSelector(selectTriggerInput);
    const focus = useSelector(selectUpdateFocus);

    const example = [
        { id: 'variant', assembly:'GRCh37', type: ['Genomic', 'HGVS'], ex: [['11 66291259 A T', '4 123664484 G -', '7 150644471 - CCGAC' ], ['NM_024649:c.1016A>T', 'NM_024649:p.His339Leu', 'BBS1:c.1016A>T', 'BBS1:p.His339Leu']] },
        { id: 'variant', assembly:'GRCh38', type: ['Genomic', 'HGVS'], ex: [['11 66523788 A T', '4 122743329 G -', '7 150947383 - CCGAC'], ['NM_024649:c.1016A>T', 'NM_024649:p.His339Leu', 'BBS1:c.1016A>T', 'BBS1:p.His339Leu']] },

        { id: 'gene', type: ['Gene', 'Gene pair'], ex: [['BBS1', 'BBS12'] ,['GFI1 MYO6']] },
        { id: 'phenotype', type: ['HPO', 'Text search'], ex: [['HP:0001738'], ['Hypogonadism']] }];

    function showExamples() {
        dispatch(setShowExample(!(visible)));
    }

    function fill(examples) {
        dispatch(setClearTimer());
        if (type.typeSearch === 'gene') {
            if (geneInfo.length !== nGene) { //set the example only if the maximum number of already validated genes has not been reached
                dispatch(setSearch(examples));
            }
        } else if (type.typeSearch === 'phenotype') {
            if (phenotypeInfo.length !== nPhenotype) { //set the example only if the maximum number of already validated genes has not been reached
                dispatch(setSearch(examples));
                dispatch(triggerInputPaste(!triggerRef));
            }
        } else {
            dispatch(setSearch(examples));
        }
        dispatch(resetWarning());
        dispatch(updateFocus(!focus));
    }

    if (visible === false) {
        return (
            <button name='examples' className='exampleButton' onClick={showExamples}><p className="Example"> Examples </p></button>
        );
    } else {
        let obj = example.filter((es) => es.id === type.typeSearch);
        if(type.typeSearch === 'variant'){
            obj = obj.filter((es) => es.assembly === type.assembly);
        }
        let divexamples = [];
        let es;
        for (let i = 0; i < obj[0].type.length; i++) {
            es = obj[0].ex[i].map((examples, j) => {
                return (
                    <p className='examples' onClick={() => fill(examples)} key={j}>{examples}</p>
                );
            });
            let name = "type" + i;
            divexamples[i] = <span key={name}><p className={name} key={name}>{obj[0].type[i]}</p>{es}</span>;
        }

        return (
            <>
                <button name='examples' className='exampleButton' onClick={showExamples}><p className="Example"> Examples </p></button>
                <div className='ex'>
                    {divexamples}
                </div>
            </>
        );
    }
}

export default ExampleButton;