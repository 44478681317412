import { createSlice } from '@reduxjs/toolkit';

const initialState={show: false, count:0, combinations:[], copyStartCombs:[], index:-1, button:'general'}; //count= number of combination found

export const resultsSlice = createSlice({
    name: 'showResults',
    initialState,
    reducers: {
        setShowResults: (state, action) => {
            state.show= action.payload;
        },
        setCombinations: (state, action) => {
            state.combinations= action.payload.combinations;
            state.copyStartCombs = action.payload.combinations;
            state.count= action.payload.count;
            state.index= -1;
            state.button='general';
        },
        setOnlyCombinations: (state, action) => {
            state.combinations= action.payload;
        },
        setDefaultOrder: (state) => {
            state.combinations= state.copyStartCombs;
        },
        resetResults:  (state) => {
            state.show= false;
            state.count= 0;
            state.combinations=[];
            state.index= -1;
            state.button='general';
        },
        setIndexSelectedRow: (state, action) => {
            state.index= action.payload;
        }, 
        setButtonResults: (state, action) => {
            state.button= action.payload;
        }, 
    }
});

export const { setShowResults, setCombinations, resetResults, setIndexSelectedRow, setButtonResults, setOnlyCombinations, setDefaultOrder } = resultsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectShowResults = (state) => state.showResults.show;
export const selectCombinations = (state) => state.showResults.combinations;
export const selectCombinationsCount = (state) => state.showResults.count;
export const selectIndexSelectedRow = (state) => state.showResults.index;
export const selectButtonResults = (state) => state.showResults.button;

export default resultsSlice.reducer;