import { useSelector } from "react-redux";
import { selectCount, selectPhenotype } from "../../../Slice/phenotypeSlice";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

function PhenotypeValidInfo() {
    const count = useSelector(selectCount);
    const phenotypes = useSelector(selectPhenotype);
    const invalid = phenotypes.map((phenotype) => {
        if (phenotype.code === 2) {
            return phenotype.hpo_id;
        } else {
            return null;
        }
    });

    const renderTooltipInvalidPhenotypes = (props) => (
        <Tooltip id="tooltip" {...props}>
            <div className='tool'>
                The following HPO terms are invalid and will not be searched: {invalid.join(' ')}
            </div>
        </Tooltip>);

    return (
        <div className="validity-info">
            <span className="numberValid">{count[0]}</span> <p>valid HPO terms</p>
            {count[1] !== 0 ?
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltipInvalidPhenotypes}>
                    <span style={{cursor:'pointer'}}> <span className="numberInvalid">{count[1]}</span> <p>not valid HPO terms</p></span>
                </OverlayTrigger> :
                <span><span className="numberInvalid">{count[1]}</span> <p>not valid HPO terms</p></span>}
        </div>
    );
}

export default PhenotypeValidInfo;