import './searchButton.css';
import { selectType } from '../../../Slice/typeSlice';
import { useSelector, useDispatch } from 'react-redux';
import { variantSearch } from '../validationSearchFunction/variantValidation';
import { setWarning } from '../../../Slice/warningSlice';
import { setClearTimer } from '../../../Slice/timerSlice';
import { setGene, selectGene, setPlaceholder } from '../../../Slice/geneSlice';
import { setPhenotype, selectPhenotype, setPlaceholderPh, setPhenotypeHints, setShowHint } from '../../../Slice/phenotypeSlice';
import { geneValidation, getGeneCombination } from '../validationSearchFunction/geneValidation';
import { selectSearch, setSearch, selectSearching, startSearching } from '../../../Slice/searchBarSlice';
import { phenotypeHpoValidation, getPhenotypesCombination, validatePhenotypesT } from '../validationSearchFunction/phenotypeValidation'
import { selectShowResults, setCombinations, setShowResults } from '../../../Slice/resultsSlice';
import { selectBearer } from '../../../Slice/bearerSlice';
import { setShowLogin } from '../../../Slice/modalVisibilitySlice';
import { setErrModalVal } from '../../../Slice/errModalValidationSlice';
import {selectLoader, setLoader} from "../../../Slice/loaderSlice";

function SearchButton() {
    const dispatch = useDispatch();
    const type = useSelector(selectType);
    const searchText = useSelector(selectSearch);
    const geneInfo = useSelector(selectGene);
    const phenotypeInfo = useSelector(selectPhenotype);
    const nPhenotype = 5; //max number of phenotype that we can search
    const nGene = 2;
    const token = useSelector(selectBearer);
    const showRes = useSelector(selectShowResults);
    const showLoader = useSelector(selectLoader);
    const searching = useSelector(selectSearching);

    async function search() {
        dispatch(setClearTimer());
        switch (type.typeSearch) {
            case 'variant':
                dispatch(setLoader(true));
                const variant = await variantSearch(searchText, type.assembly);
                dispatch(setLoader(false));
                if (variant.length > 2) {
                    let geneName;
                    switch (variant[2].code) {
                        case 1:
                            geneName = searchText.split(':');
                            dispatch(setWarning({ show: true, value: geneName[0] + ' gene will be searched using its synonym ' + variant[1].gene }));
                        case 0:
                            dispatch(setShowResults(true));
                            dispatch(setCombinations(variant[1]));
                            break;
                        case 2:
                            geneName = searchText.split(':');
                            dispatch(setWarning({ show: true, value: geneName[0] + ' gene has not been found' }));
                            break;
                        default:
                            break;
                    }
                } else if (variant[0]) {
                    dispatch(setShowResults(true));
                    dispatch(setCombinations(variant[1]));
                } else if (!variant[0] && searchText.replace(/\s+/g, '') !== '') {
                    dispatch(setWarning({ show: true, value: 'Invalid search format' }));
                }
                break;
            case 'gene':
                let geneList = []; //list of valid genes
                dispatch(startSearching(true));
                geneInfo.map((gene) => {
                    if (gene.code !== 2) {
                        geneList.push(gene.gene);
                    }
                    return null;
                });
                if (searchText.replace(/\s+/g, '') !== '') { //search without validate first
                    const gene = await validateGene(searchText);
                    for (const element of gene) {
                        if (element.code === 0 || element.code === 1) {
                            geneList.push(element.gene);
                        }
                    }
                }
                //entro solo se mando dei geni, se invio una stringa vuota o dei geni invalid non faccio call al back-end
                if (geneList.length !== 0) {
                    searchGeneOrPh(getGeneCombination, geneList);
                } else {
                    dispatch(startSearching(false));
                }
                break;
            case 'phenotype':
                if(searchText.trimStart().length > 2 && !searchText.toUpperCase().trimStart().startsWith('HP:')){ //if is a text and contains more than 2 characters start the text search
                    dispatch(setShowHint(false));  
                    validatePhenotypesText(searchText);
                    break;
                } else {
                    let phenoList = []; //list of valid phenotypes
                    dispatch(startSearching(true));
                    phenotypeInfo.map((phenotype) => {
                        if (phenotype.code !== 2) {
                            phenoList.push(phenotype.hpo_id);
                        }
                        return null;
                    });
                    //search without validate first HPO id
                    if (searchText.toUpperCase().trimStart().startsWith('HP:')) {
                        const phen = await validatePhenotypesHpo(searchText);
                        for (const element of phen) {
                            if (element.code === 0 || element.code === 1) {
                                phenoList.push(element.hpo_id);
                            }
                        }
                    } else {
                        dispatch(setSearch(""));
                    }
                    //entro solo se mando dei geni, se invio una stringa vuota o dei geni invalid non faccio call al back-end
                    if (phenoList.length !== 0) {
                        searchGeneOrPh(getPhenotypesCombination, phenoList);
                    } else {
                        dispatch(startSearching(false));
                    }
                    break;
                }
            default:
                break;
        }
    }

    //get genes or phenotypes combinations
    async function searchGeneOrPh(func, list) {
        if (token !== '') {
            dispatch(setLoader(true));
            const combinations = await func(list, token);
            if (combinations !== null) {
                dispatch(setShowResults(true));
                dispatch(setCombinations(combinations));
            } else {
                dispatch(setShowResults(!showRes)); //to force rerender
            }
        } else {
            dispatch(setShowLogin(true));
            dispatch(setErrModalVal({ show: true, value: 'In order to search you need to login.' }));
        }
        dispatch(startSearching(false));
    }

    //valido il gene
    async function validateGene(searchText) {
        dispatch(setLoader(true));
        const validate = await geneValidation(searchText, nGene, geneInfo.length);
        //0 valid, 1 synonym, 2 invalid
        for (const element of validate) {
            switch (element.code) {
                case 0:
                    dispatch(setGene(element));
                    dispatch(setPlaceholder(""));
                    dispatch(setSearch(""));
                    break;
                case 1:
                    dispatch(setGene(element));
                    dispatch(setPlaceholder(""));
                    dispatch(setSearch(""));
                    break;
                case 2:
                    dispatch(setGene(element));
                    dispatch(setPlaceholder(""));
                    dispatch(setSearch(""));
                    break;
                default:
                    break;
            }
        }
        return validate;
    }

    // valido il fenotipo
    async function validatePhenotypesHpo(searchText) {
        dispatch(setLoader(true));
        const validate = await phenotypeHpoValidation(searchText, nPhenotype, phenotypeInfo.length);
        //0 valid, 1 obsolete, 2 invalid
        for (let i = 0; i < validate.length; i++) {
            switch (validate[i].code) {
                case 0:
                    dispatch(setPhenotype(validate[i]));
                    dispatch(setPlaceholderPh(""));
                    dispatch(setSearch(""));
                    break;
                case 1:
                    dispatch(setPhenotype({ code: validate[i].code, hpo_id: validate[i].hpo_id, obsolete: searchText })); //da sistemare
                    dispatch(setPlaceholderPh(""));
                    dispatch(setSearch(""));
                    break;
                case 2:
                    dispatch(setPhenotype(validate[i]));
                    dispatch(setPlaceholderPh(""));
                    dispatch(setSearch(""));
                    break;
                default:
                    break;
            }
        }
        return validate;
    }

    //Text search
    async function validatePhenotypesText(searchText) {
        let validate = await validatePhenotypesT(searchText);
        validate.phenotypes= validate.phenotypes.map(obj => ({ ...obj, code: 0 }));
        dispatch(setSearch(validate.search));
        dispatch(setPhenotypeHints(validate.phenotypes));
        dispatch(setShowHint(true));
    }

    return (!showLoader ?
            (<button name='search' className='searchButton' onClick={search}>
                <span className='searchContent'>
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none">
                            <path d="M0 0h24v24H0z"/>
                            <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" fill="#FFF"/>
                        </g>
                    </svg>
                    <p className="Search"> SEARCH </p>
                </span>
            </button>)
            :(
            <div className='divLoader' >
                <span className="spinner spinner-custom">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                    <div className="bounce4"></div>
                </span>
            </div>
        ));

}

export default SearchButton;