import { createSlice } from '@reduxjs/toolkit';

const initialState={value: false}; //da mettere a false 

// visibility of the modal
export const timerSlice = createSlice({
    name: 'timer',
    initialState,
    reducers: {
        setTimer: (state, action) => {
            state.value= action.payload;
        },
        setClearTimer: (state) => {
            clearTimeout(state.value);
        },
    }
});

export const {setClearTimer , setTimer} = timerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectTimer = (state) => state.timer.value;

export default timerSlice.reducer;