import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import {selectLogin, setShowLogin, setCiteUs, setChangelog, setHelpCenter} from '../../Slice/modalVisibilitySlice';
import CiteUs from "./Modal/CiteUs/CiteUs";
import Changelog from "./Modal/Changelog";
import HelpCenter from "./HelpCenter/HelpCenter";
function NavBarNotLog() {
    const show = useSelector(selectLogin);
    const dispatch = useDispatch();

    function showModal() {
        dispatch(setShowLogin(!show));
    }

    function showCiteUs(){
        dispatch(setCiteUs(true));
    }

    function showChangelog(){
        dispatch(setChangelog(true));
    }

    function showHelpCenter(){
        dispatch(setHelpCenter(true));
    }

    return (
        <Col className='right-side'>
            <ul className='nav'>
                <li className='help1'><span title="Help Center" onClick={showHelpCenter} className='help'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" /></svg>
                    Help Center</span> </li>
                <li className='menu1'> <span className='menu'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><g fill="none"><path d="M0 0h24v24H0V0z" /><path d="M0 0h24v24H0V0z" opacity=".87" /></g><path d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7zm-4 6h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z" /></svg>
                    About us</span> <ul className='about'><li onClick={showCiteUs}> Cite Oliver</li>
                        <li onClick={showChangelog}>Changelog</li></ul></li>
                <li onClick={showModal} className='login1'><span className='login'>
                    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffffff"><g><rect fill="none" height="24" width="24" /></g><g><path d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z" /></g></svg>
                    Login</span></li>
            </ul>
            <CiteUs/>
            <Changelog/>
            <HelpCenter/>
        </Col>
    );
}

export default NavBarNotLog;