import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import '../Modal/Modal.css';
import {selectHelpCenter, setHelpCenter} from "../../../Slice/modalVisibilitySlice";
import RegistrationHelper from "./RegistrationHelper";
import ResultsHelper from "./ResultsHelper";
import SearchingHelper from "./SearchingHelper";
import NavigationHelper from "./NavigationHelper";
import elementDownload from "../../../pdf/oliVerUserguidev1.0.pdf";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function HelpCenter() {
    const show = useSelector(selectHelpCenter);
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(setHelpCenter(false));
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" className='modal' dialogClassName="modal-w-cust">
                <Modal.Header>
                    <Modal.Title className='title'>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0V0z" fill="none" /><path fill='#344d66' d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" /></svg>
                        Help Center</Modal.Title>
                    <Button onClick={handleClose} id='close'>
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z" /><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
                    </Button>
                </Modal.Header>
                <Modal.Body className='body help-modal'>
                    <Container>
                        <div>Welcome to Oliver's Help Center! Here, you can find indications on how to use the platform.<br/><br/>
                            <div style={{marginBottom: '12px'}}><b>Download our User Guide</b><a href={elementDownload} download='Oliver User Guide'><FontAwesomeIcon className = 'download-icon' icon={faDownload} /></a></div></div>
                        <RegistrationHelper/>
                        <SearchingHelper/>
                        <ResultsHelper/>
                        <NavigationHelper/>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default HelpCenter;