import { configureStore } from '@reduxjs/toolkit';
import typeReducer from '../Slice/typeSlice';
import visibilityReducer from '../Slice/modalVisibilitySlice';
import searchBarReducer from '../Slice/searchBarSlice';
import logReducer from '../Slice/logSlice';
import passwordReducer from '../Slice/passwordSlice';
import dataReducer from '../Slice/userDataSlice';
import warningReducer from '../Slice/warningSlice';
import bearerReducer from '../Slice/bearerSlice';
import errorModalValReducer from '../Slice/errModalValidationSlice';
import messageProfileValReducer from '../Slice/messageProfileValidationSlice';
import geneReducer from '../Slice/geneSlice';
import phenotypeReducer from '../Slice/phenotypeSlice';
import timerReducer from '../Slice/timerSlice';
import showResultsReducer from '../Slice/resultsSlice';
import loaderReducer from '../Slice/loaderSlice';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import { persistStore, persistReducer } from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';

import thunk from 'redux-thunk';
import createFilter from 'redux-persist-transform-filter';

const persistConfig = {
  key: 'root',
  storage: storageSession,
}

const persistConfigToken = {
  key: 'bearer',
  storage,
  whitelist: ['value']
}

const persistConfigLog = {
  key: 'log',
  storage,
  whitelist: ['value']
}

const filter = createFilter(
  `value.user`
);

const persistConfigUser = {
  key: 'user',
  storage,
  transforms: [filter]
}

const persistedReducer = combineReducers({
  type: typeReducer,
  visibility: visibilityReducer,
  searchbar: searchBarReducer,
  log: persistReducer(persistConfigLog, logReducer),
  password: passwordReducer,
  data: persistReducer(persistConfigUser, dataReducer),
  warning: warningReducer,
  bearer: persistReducer(persistConfigToken, bearerReducer),
  errorModalVal: errorModalValReducer,
  loader: loaderReducer,
  messageProfileVal: messageProfileValReducer,
  gene: geneReducer,
  timer: timerReducer,
  phenotype: phenotypeReducer,
  showResults: showResultsReducer
})

const tokenPersistedReducer = persistReducer(persistConfig, persistedReducer);

export const store = configureStore({
  reducer: tokenPersistedReducer,
  middleware: [thunk]
});


export const persistor = persistStore(store)

// persistor.purge()