let Url=''

if(process.env.REACT_APP_ENV === 'production'){
    Url = 'https://oliver.engenome.com'
    // console.log('prod')
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
} else {
    Url = 'https://dev.oliver.engenome.com'
    console.log('dev')
}

//to create the build
//npm run build:dev  -> dev env
//npm run build:prod  -> prod env

//to start the environment locally
// npx serve -s build 

export {Url}