import { useState } from 'react';
import './HelpCenter.css';
import PageHelperEntry from "./PageHelperEntry";
import generalTab from '../../../Images/general-tab.png';
import genesTab from '../../../Images/genes-tab.png';
import phenotypesTab from '../../../Images/phenotypes-tab.png';
import geneInteractionTab from '../../../Images/geneinteraction-tab.png';

function NavigationHelper() {
    const [open, setOpen] = useState(true);
    const [rotate, setRotate] = useState(null);
    let generalTabComp = {title:'General Tab', content: comp1()};
    let genesTabComp = {title:'Genes Tab', content: comp2()};
    let phenotypesTabComp = {title:'Phenotypes Tab', content: comp3()};
    let geneInteractionTabComp = {title:'Gene Interaction Tab', content: comp4()};

    function comp1(){
        return (<><div className={open ? 'show' : 'notshow'}>
            When choosing a combination in the results table, the <img className='img-button img-transform' src={generalTab} alt='General'></img> tab is opened by default. <br/>
            In this section, generic information about the digenic combination is displayed. <br/>
            An image representing the prediction
            is shown. The prediction may fall under one of these categories:<br/>
            <ul>
                <li>True Digenic</li>
                <li>Composite</li>
                <li>Dual Molecular Diagnosis</li>
                <li>True Digenic or Composite</li>
                <li>NaN (if not enough information was available to predict the digenic
                mechanism)</li>
            </ul><br/>
            On the right, a section containing specific information on how the prediction has been calculated and
            what information impacted it the most is present. The score regarding the impact on the prediction is discretized in 4 possible values, which are:
            <ol>
                <li style={{color:'rgb(238, 131, 115)'}}>low</li>
                <li style={{color:'rgb(255, 194, 14)'}}>medium</li>
                <li style={{color:'rgb(90, 190, 153)'}} >significant</li>
                <li style={{color:'rgb(90, 190, 186)'}} >very high</li>
            </ol></div></>);
    }

    function comp2(){
        return (<p className={open ? 'show' : 'notshow'}>
            By clicking on the <img className='img-button img-transform' src={genesTab} alt='Genes'></img> tab, information
            regarding the gene variants participating in the combination are displayed.
            Each gene of the combination has its MANE 'select' transcript displayed at the top of the list by default.<br/>
            If present, additional transcripts of the same gene can be shown by clicking on the 'Show more...'.<br/>
            For each transcript, HGVS coding sequence and HGVS protein (if available) of the variant are displayed.<br/>
        </p>);
    }

    function comp3(){
        return (<p className={open ? 'show' : 'notshow'}>
            By clicking on the <img className='img-button img-transform' src={phenotypesTab} alt='Phenotypes'/> button, the 'phenotypes' tab is opened.<br/>
            Here, all phenotypes associated
            with the combination are listed. On top of the page, an informativity index is displayed, which indicates the strength of the
            phenotypic description associated with the combination. Three level of
            informativity are available: weak, fair and strong. <br/>
            For each phenotype, the HPO term, name, and association with each gene in the pair are shown.
            It's possible to sort phenotypes with each column value by clicking on the corresponding header.
        </p>);
    }

    function comp4(){
        return (<div className={open ? 'show' : 'notshow'}>
            By clicking on the <img className='img-button img-transform' src={geneInteractionTab} alt='Gene Interaction'/> button, a section
            containing information extracted from <a style={{textDecoration: 'none'}} target='_blank' rel='noreferrer' href='https://string-db.org/'>STRING</a> and <a style={{textDecoration: 'none'}} target='_blank' rel='noreferrer' href='https://genemania.org/'>GeneMANIA</a> is shown. These are two external websites
            that focus on gene interaction analysis. By clicking on GeneMANIA and STRING links, the corresponding page of the combination is opened.<br/>
            At the center of this section, the gene interaction network provided by STRING is present. This graphically represents the existing relationship between the two genes.<br/>
            Such network can be interacted with by clicking on the gene elements or on the
            connection between the genes. By doing so, a card which gives out information about the gene or the gene connection is displayed.
            Moreover, a summary of the levels of evidence supporting the interaction between the genes is reported in this tab.</div>);
    }

    return (<><div>
        <div  className='titleHelpCenter' onClick={() => {setOpen(!open);
            if(rotate !==null) {
                setRotate(!rotate);
            }else{
                setRotate(true);
            }
        }} >Navigation of a Combination
            <svg className={rotate !== null ? (rotate ? 'svgClickOpen' : ''): ''} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#344d66"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87" /><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" /></svg>
        </div>

        <div className={open ? 'show' : 'notshow'}>
            <ul style={{listStyle:'none'}}>
                <li><PageHelperEntry props={generalTabComp} /></li>
                <hr/>
                <li><PageHelperEntry props={genesTabComp} /></li>
                <hr/>
                <li><PageHelperEntry props={phenotypesTabComp} /></li>
                <hr/>
                <li><PageHelperEntry props={geneInteractionTabComp} /></li>
            </ul>
        </div>
    </div></>);

}

export default NavigationHelper;