import { Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { selectMailForgotSent, setMailForgotSent } from '../../../Slice/modalVisibilitySlice';
import './Modal.css';

function MailForgotSent() {
  const show = useSelector(selectMailForgotSent);
  const dispatch= useDispatch();

  const handleClose = () => {
    dispatch(setMailForgotSent(false));
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" className='modal'>
      <Modal.Header>
          <Modal.Title className='title'>
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5abeba"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
            Email sent!</Modal.Title>
          <Button onClick={handleClose} id='close'>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z" /><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <p>An email has been sent to your address.<br /> Please check your email to reset your password.
              </p>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MailForgotSent;