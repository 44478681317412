import { createSlice } from '@reduxjs/toolkit';

const initialState = { login: false, signup: false, example: false,
    profile: false, registrationSuccess: false, mailForgotSent: false,
    resetPassword: false, privacy:false, citeus:false, termsOfUse:false,
    changelog:false, helpCenter:false, activate:false };

// visibility of the modal
export const modalVisibilitySlice = createSlice({
    name: 'visibility',
    initialState,
    reducers: {
        setShowLogin: (state, action) => {
            state.login = action.payload;
        },
        setShowSignUp: (state, action) => {
            state.signup = action.payload;
        },
        setShowExample: (state, action) => {
            state.example = action.payload;
        },
        setShowProfile: (state, action) => {
            state.profile = action.payload;
        },
        setShowRegistrationSuccess: (state, action) => {
            state.registrationSuccess = action.payload;
        },
        setMailForgotSent: (state, action) => {
            state.mailForgotSent = action.payload;
        },
        setResetPassword: (state, action) => {
            state.resetPassword = action.payload;
        },
        setPrivacyPolicy: (state, action) => {
            state.privacy = action.payload;
        },
        setCiteUs: (state, action) => {
            state.citeus = action.payload;
        },
        setTermsOfUse: (state, action) => {
            state.termsOfUse = action.payload;
        },
        setChangelog: (state, action) => {
            state.changelog = action.payload;
        },
        setHelpCenter: (state, action) => {
            state.helpCenter = action.payload;
        },
        setActivateAccount: (state, action) => {
            state.activate = action.payload;
        },
        setVisibilityInit: (state) => {
            state.login = false;
            state.signup = false;
            state.example = false;
            state.profile = false;
            state.registrationSuccess = false;
            state.mailForgotSent = false;
            // state.resetPassword = true;
            state.privacy = false;
            state.citeus = false;
            state.changelog = false;
        }
    }
});

export const { setShowLogin, setShowSignUp, setShowExample, setShowProfile, setShowRegistrationSuccess,
    setMailForgotSent, setResetPassword, setVisibilityInit, setPrivacyPolicy, setCiteUs, setTermsOfUse,
    setChangelog, setHelpCenter, setActivateAccount} = modalVisibilitySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectLogin = (state) => state.visibility.login;
export const selectSignUp = (state) => state.visibility.signup;
export const selectExample = (state) => state.visibility.example;
export const selectProfile = (state) => state.visibility.profile;
export const selectRegistrationSuccess = (state) => state.visibility.registrationSuccess;
export const selectMailForgotSent = (state) => state.visibility.mailForgotSent;
export const selectResetPassword = (state) => state.visibility.resetPassword;
export const selectPrivacyPolicy = (state) => state.visibility.privacy;
export const selectCiteUs = (state) => state.visibility.citeus;
export const selectTermsOfUse = (state) => state.visibility.termsOfUse;
export const selectChangelog = (state) => state.visibility.changelog;
export const selectHelpCenter = (state) => state.visibility.helpCenter;
export const selectActivateAccount = (state) => state.visibility.activate;

export default modalVisibilitySlice.reducer;