function NoResults() {
    return (
        <div className="no-results">
        <svg width="101" height="101" viewBox="0 0 101 101" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path d="M1.009 50.514c0 27.34 22.164 49.505 49.504 49.505 27.341 0 49.505-22.165 49.505-49.505 0-27.34-22.164-49.505-49.505-49.505-27.34 0-49.504 22.164-49.504 49.505" fill="#FFF" />
                <path d="M99.037 50.514c0 26.798-21.725 48.523-48.524 48.523-26.798 0-48.523-21.725-48.523-48.523 0-26.8 21.725-48.524 48.523-48.524 26.8 0 48.524 21.725 48.524 48.524z" stroke="#314C67" strokeWidth="2" />
                <path d="M50.513 97.066c-25.669 0-46.552-20.883-46.552-46.554 0-25.667 20.883-46.551 46.552-46.551 25.67 0 46.553 20.884 46.553 46.551 0 25.67-20.883 46.554-46.553 46.554z" stroke="#4FC7BE" strokeWidth="2" />
                <path d="M51.567 28.274c-6.453-6.453-16.915-6.453-23.37 0-6.452 6.455-6.452 16.917 0 23.37 6.455 6.454 16.917 6.454 23.37 0 6.455-6.453 6.455-16.915 0-23.37" fill="#FFF" />
                <path d="M51.567 28.274c-6.453-6.453-16.915-6.453-23.37 0-6.452 6.455-6.452 16.917 0 23.37 6.455 6.454 16.917 6.454 23.37 0 6.455-6.453 6.455-16.915 0-23.37z" stroke="#314C67" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M47.863 47.94c-4.407 4.408-11.553 4.408-15.961 0-4.407-4.407-4.407-11.554 0-15.962 4.408-4.407 11.554-4.407 15.961 0 4.408 4.408 4.408 11.555 0 15.962" fill="#4FC7BE" />
                <path d="M47.863 47.94c-4.407 4.408-11.553 4.408-15.961 0-4.407-4.407-4.407-11.554 0-15.962 4.408-4.407 11.554-4.407 15.961 0 4.408 4.408 4.408 11.555 0 15.962z" stroke="#344D66" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path stroke="#FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="m34.96 44.88 9.845-9.842M44.805 44.88l-9.843-9.842" />
                <path stroke="#314C67" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="m51.644 51.567 5.402 5.403" />
                <path fill="#4FC7BE" d="m77.669 71.968-5.625 5.625-17.81-17.811 5.625-5.624z" />
                <path stroke="#344D66" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="m77.669 71.968-5.625 5.625-17.81-17.811 5.625-5.624z" />
            </g>
        </svg>
        <p>No Combinations to show!</p>
        </div>
    );
}

export default NoResults;