import { useDispatch, useSelector } from 'react-redux';
import { selectType, changeTypeSearch, setInitType } from '../../../Slice/typeSlice';
import { setSearch } from '../../../Slice/searchBarSlice';
import { setShowExample } from '../../../Slice/modalVisibilitySlice';
import './typeSearch.css';
import { resetWarning } from '../../../Slice/warningSlice';
import { resetInitialValue } from '../../../Slice/geneSlice';
import { resetInitialValuePh } from '../../../Slice/phenotypeSlice';
import { setClearTimer } from '../../../Slice/timerSlice';

function TypeSearch() {
    const type = useSelector(selectType);
    const dispatch = useDispatch();
    function changeSearch(event) {
        if (event.target.value === 'variant') {
            dispatch(setInitType());
        } else {
            dispatch(changeTypeSearch(event.target.value));
        }
        dispatch(setSearch(''));
        dispatch(setShowExample(false));
        dispatch(resetWarning());
        dispatch(resetInitialValue());
        dispatch(resetInitialValuePh());
        dispatch(setClearTimer());
    }

    return (
        <select name='typeSearch' className='typeSearch' value={type.typeSearch} onChange={changeSearch}>
            <option value='variant'>Variant</option>
            <option value='gene'>Gene</option>
            <option value='phenotype'>Phenotype</option>
        </select>
    );
}

export default TypeSearch;