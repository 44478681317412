import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: { typeSearch: 'variant', assembly: 'GRCh38' }
};

export const typeSlice = createSlice({
  name: 'type',
  initialState,
  reducers: {
    changeTypeSearch: (state, action) => {
      state.value.typeSearch = action.payload;
    },
    changeTypeAssembly: (state, action) => {
      state.value.assembly = action.payload;
    },
    setInitType: (state) => {
      state.value = initialState.value;
    }
  }
});

export const { changeTypeSearch, changeTypeAssembly, setInitType } = typeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectType = (state) => state.type.value;

export default typeSlice.reducer;