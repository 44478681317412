import {Url} from '../../../constants/global';
export async function phenotypeHpoValidation(strings_array_in, nPhenotype, phenotypeVal) { //nPhenotype= max num of phenotypes that accept, phenotypeVal= number of phenotypes already validated
    let phenotype = [];
    let phToValidate = [];
    let str_vett = [];
    strings_array_in = strings_array_in.toUpperCase();
    strings_array_in = strings_array_in.split(' ');
    for (let i = 0; i < strings_array_in.length; i++) {
        if (strings_array_in[i].split(',').length !== 1) {
            str_vett = str_vett.concat(strings_array_in[i].split(','));
        } else {
            str_vett = str_vett.concat(strings_array_in[i].split('\t')); //add tab if there is a copy and paste from excel
        }
    }
    strings_array_in = str_vett.filter((str) => str !== "")//delete empty string
    strings_array_in = str_vett.filter((str) => str.startsWith('HP:'))//delete string that not starts with hp:
    strings_array_in = strings_array_in.splice(0, nPhenotype - phenotypeVal); //valid only as many phenotypes as are missing to reach the max

    for (let i = 0; i < strings_array_in.length; i++) {
        if (/^HP:[0-9]{7}$/.test(strings_array_in[i])) {
            phToValidate.push(strings_array_in[i]);
        } else {
            phenotype.push({ code: 2, hpo_id: strings_array_in[i] }); //not valid
        }
    }
    phenotype = phenotype.concat(await validatePhenotypes(phToValidate));
    return phenotype;
}

async function validatePhenotypes(phenotypes) {
    try {
        const response = await fetch(Url + '/oliver/phenotypes/validate-phenotypes?' + new URLSearchParams({ hpo_list: phenotypes }), {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
            throw new Error(`HTTP error: ${response.status}`);
        } else {
            const data = await response.json();
            return data.phenotypes;
        }
    } catch (error) {
        console.error(`Could not get products: ${error}`);
    }
}

export async function validatePhenotypesT(phenotype) {
    try {
        const response = await fetch(Url + '/oliver/phenotypes/text-search-phenotype?' + new URLSearchParams({ hint: phenotype }), {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
            throw new Error(`HTTP error: ${response.status}`);
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(`Could not get products: ${error}`);
    }
}

export async function getPhenotypesCombination(phenotypes, logToken) {
    try {
        const response = await fetch(Url + '/oliver/combination/phenotype-search?' + new URLSearchParams({ hpo_list: phenotypes }), {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${logToken}` }
        });

        if (!response.ok) {
            if (response.status === 500) {
                return null;
            }
            throw new Error(`HTTP error: ${response.status}`);
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(`Could not get products: ${error}`);
    }
}