import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import { setLog } from '../../Slice/logSlice';
import {setChangelog, setCiteUs, setHelpCenter, setShowProfile} from '../../Slice/modalVisibilitySlice';
import { selectUserData, setUserData, resetUserData } from '../../Slice/userDataSlice';
import { setBearer, selectBearer } from '../../Slice/bearerSlice';
import { setErrModalValInit } from '../../Slice/errModalValidationSlice';
import { resetInitialValue } from '../../Slice/geneSlice';
import { resetInitialValuePh } from '../../Slice/phenotypeSlice';
import { resetResults } from '../../Slice/resultsSlice';
import { setMessageProfileValInit } from '../../Slice/messageProfileValidationSlice';
import { setVisibilityInit } from '../../Slice/modalVisibilitySlice';
import { setSearch } from '../../Slice/searchBarSlice';
import jwt_decode from "jwt-decode";
import { setInitType } from '../../Slice/typeSlice';
import { setShowExample } from '../../Slice/modalVisibilitySlice';
import { setClearTimer } from '../../Slice/timerSlice';
import {resetLoader} from "../../Slice/loaderSlice";
import { resetWarning } from '../../Slice/warningSlice';
import CiteUs from "./Modal/CiteUs/CiteUs";
import Changelog from "./Modal/Changelog";
import HelpCenter from "./HelpCenter/HelpCenter";
import {Url} from '../../constants/global';

function NavBarLog() {
    const dispatch = useDispatch();
    const data = useSelector(selectUserData);
    const logToken = useSelector(selectBearer);
    const decodeLogToken = jwt_decode(logToken);
    let date = new Date();

    function logOut() {
        dispatch(setLog(false));
        dispatch(setBearer(''));
        dispatch(resetUserData());
        dispatch(setShowExample(false));
        dispatch(setClearTimer());
        dispatch(resetLoader());
        dispatch(resetInitialValue());
        dispatch(resetInitialValuePh());
        dispatch(resetResults());
        dispatch(setSearch(''));
        dispatch(setInitType());
        dispatch(resetWarning());
    }

    function showCiteUs(){
        dispatch(setCiteUs(true));
    }
    function showChangelog(){
        dispatch(setChangelog(true));
    }

    function showHelpCenter(){
        dispatch(setHelpCenter(true));
    }

    function openProfile() {
        const fetchPromise = fetch(Url + '/oliver/users', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${logToken}` }
        });
        fetchPromise
            .then((response) => {
                if (!response.ok) {
                    if(response.status === 500){
                        expiredToken();
                    }
                    throw new Error(`HTTP error: ${response.status}`); //da vedere se deve succedere altro
                } else {
                    return response.json();
                }
            })
            .then((data1) => {
                dispatch(setUserData({ firstname: data1.firstName, lastname: data1.lastName, user: data1.username, email: data1.email, aff: data1.institution, country: data1.country }))
                dispatch(setShowProfile(true));
            })
            .catch((error) => {
                console.error(`Could not get products: ${error}`);
            });
    }

    if (decodeLogToken.exp * 1000 < date.getTime()) {
        console.log(decodeLogToken.exp);
        expiredToken();
    }

    function expiredToken(){
        logOut();
        dispatch(setErrModalValInit());   
        dispatch(setMessageProfileValInit());
        dispatch(setVisibilityInit());
        alert("Your session has expired.");
    }

    return (
        <Col className='right-side'>
            <ul className='nav'>
                <li className='help1'><span href="" title="Help Center" className='help' onClick={showHelpCenter}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" /></svg>
                    Help Center</span> </li>
                <li className='menu1'> <span className='menu'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><g fill="none"><path d="M0 0h24v24H0V0z" /><path d="M0 0h24v24H0V0z" opacity=".87" /></g><path d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7zm-4 6h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z" /></svg>
                    About us</span> <ul className='about'><li onClick={showCiteUs}> Cite Oliver</li>
                        <li onClick={showChangelog}>Changelog</li></ul></li>
                <li className='user1'> <span>{data.user}
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87" /><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" /></svg>
                </span>
                    <ul><li onClick={openProfile}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffffff"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z" /></svg>
                        Profile</li>
                        <li onClick={logOut}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><g><path d="M0,0h24v24H0V0z" fill="none" /></g><g><path d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z" /></g></svg>
                            Log out</li>
                    </ul>
                </li>
            </ul>
            <CiteUs/>
            <Changelog/>
            <HelpCenter/>
        </Col>
    );
}

export default NavBarLog;