import { createSlice } from '@reduxjs/toolkit';

const initialState={value:false};

export const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.value= action.payload;
        },
        resetLoader: (state, action) => {
            state.value= false;
        },
    }
});

export const { setLoader, resetLoader } = loaderSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectLoader = (state) => state.loader.value;

export default loaderSlice.reducer;