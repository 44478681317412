import { createSlice } from '@reduxjs/toolkit';

const initialState={value:[
    {id:'personalInfo',show: false, value:'', type:''},
    {id:'password',show: false, value:'', type:''},
    {id:'email',show: false, value:'', type:''},
    {id:'country',show: false, value:'', type:''}
]};

export const messageProfileValidationSlice = createSlice({
    name: 'messageProfileVal',
    initialState,
    reducers: {
        setMessageProfileVal: (state, action) => {
            state.value= action.payload;
        },
        setMessageProfileValInit: (state) =>{
            state.value = initialState.value;
        }
    }
});

export const { setMessageProfileVal, setMessageProfileValInit } = messageProfileValidationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectMessageProfileVal = (state) => state.messageProfileVal.value;

export default messageProfileValidationSlice.reducer;