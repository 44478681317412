import Phenotype from './Phenotype';
import { useSelector } from 'react-redux';
import { selectPhenotype } from '../../../Slice/phenotypeSlice';

function PhenotypeList() {
    const phenotypeList = [];
    const phenotypes = useSelector(selectPhenotype);

    if (phenotypes.length !== 0) {
        phenotypes.map((phenotype, i) => {
            phenotypeList.push(<Phenotype value={phenotype} key={i} index={i} />);
            return null;
        });
    }

    return (
        <span className='phenotypeList'>{phenotypeList}</span>
    );
}

export default PhenotypeList;