import Country from '../Country';
import { Row, Col } from 'react-bootstrap';
import { selectUserData, setUserData } from '../../../../Slice/userDataSlice';
import Form from 'react-bootstrap/Form';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { selectMessageProfileVal, setMessageProfileVal } from '../../../../Slice/messageProfileValidationSlice';
import { selectBearer } from '../../../../Slice/bearerSlice';
import { useState } from 'react';
import {Url} from '../../../../constants/global';

function ChangeAff(props) {
    let messageCountry;
    const data = useSelector(selectUserData);
    const messageValidation = useSelector(selectMessageProfileVal);
    const [open, setOpen] = useState(false);
    const logToken = useSelector(selectBearer);
    const dispatch = useDispatch();

    function reset(e) {
        e.preventDefault();
        props.val[1]({ ...props.val[0], country: false });
        props.edit[1]({ ...props.edit[0], aff: '' });
        props.country[1](data.country);
    }

    function handleSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();
        props.val[1]({ ...props.val[0], country: true });
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            if (!((props.edit[0].aff === '' || data.aff === props.edit[0].aff) && (props.country[0] === '' || data.country === props.country[0]))) {
                putCountryAff();
            }
        }
    }

    //function to send data
    function putCountryAff() {
        const fetchPromise = fetch(Url + '/oliver/users/change-country-institution', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${logToken}` },
            body: JSON.stringify({
                country: props.country[0],
                institution: props.edit[0].aff
            })
        });
        fetchPromise
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error: ${response.status}`); //da vedere se deve succedere altro
                } else {
                    dispatch(setMessageProfileVal(messageValidation.map((err) => {
                        if (err.id === 'country') {
                            return { ...err, show: true, value: 'Info updated!', type: 'success' };
                        } else {
                            return { ...err };
                        }
                    })));
                    return response.json();
                }
            })
            .then((data1) => {
                dispatch(setUserData({ ...data, country: data1.country, aff: data1.institution }));
                props.val[1]({ ...props.val[0], country: false });
                props.edit[1]({ ...props.edit[0], aff: '' });
            })
            .catch((error) => {
                console.error(`Could not get products: ${error}`);
            });
    }

    messageValidation.map((message) => {
        if (message.id === 'country' && message.show === true) {
            if (message.type === 'success') {
                messageCountry = <p className='mb-2 successMessage'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5abeba"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" /></svg>
                    {message.value}</p>;
            }
        }
        return null;
    });

    return (
        <Form noValidate validated={props.val[0].country} onSubmit={(e) => { handleSubmit(e, 'country') }}><Row>
            <Form.Label className='profile-label' onClick={() => { setOpen(!open) }}>Change country or affiliation
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#344d66"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87" /><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" /></svg>
            </Form.Label>
            {open ?
                <span className='sez'>
                    <Row className="mb-2 data">
                        <Col><label>AFFILIATION</label></Col>
                        <Col><input type="text" id='aff' placeholder={data.aff} pattern='[A-zÀ-ú0-9\s\-_\.]*[A-zÀ-ú]{3,}[A-zÀ-ú0-9\s\-_\.]*' value={props.edit[0].aff} minLength={3} onChange={(e) => { props.edit[1]({ ...props.edit[0], aff: e.target.value }); props.handleChange('country', e) }} />
                            <Form.Control.Feedback type="invalid" className='invalid'>Please enter your affiliation.</Form.Control.Feedback></Col>
                    </Row>
                    <Row className="mb-2 data">
                        <Col><label>COUNTRY</label></Col>
                        <Col><Country mystate={props.country[0] !== '' ? [props.country[0], props.country[1]] : [data.country, props.country[1]]} />
                            <Form.Control.Feedback type="invalid" className='invalid'>Please select a country.</Form.Control.Feedback></Col>
                    </Row>
                    <Row><Col className='no-wrap saveXcol'><Button className="mb-2 save" type='submit'>SAVE</Button>
                        <Button className="mb-2 cancel" type='reset' onClick={(e) => reset(e)}>X</Button></Col>
                        <Col> {messageValidation[3].show ? messageCountry : null}</Col>
                    </Row>
                </span> : null}
        </Row></Form>
    );
}

export default ChangeAff;