import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import HeaderBar from './components/headerBar/HeaderBar'
import SearchPanel from './components/searchPanel/SearchPanel'
import FooterBar from './components/footerBar/FooterBar'
import './Dashboard.css'
import { Outlet } from "react-router-dom";
import { selectShowResults } from './Slice/resultsSlice';
import { useSelector } from 'react-redux';
import smallLogo from './Images/OliverLogo-WhiteBackground.png';

function Dashboard() {
    const showResults = useSelector(selectShowResults);

    return (
        <>
            <Container fluid className='cont'>
                <HeaderBar />
                {showResults ? null : <Row> <div className='Description' align='center'>
                <img src={smallLogo} alt="Oliver" height={'20px'}/> is the open-access digenic platform powered by <a href='https://www.engenome.com/' target='_blank' rel="noreferrer"> enGenome</a>.
                </div> </Row>}
                <div id="detail">
                    <Outlet />
                </div>
                <SearchPanel />
            </Container>
            <FooterBar />
        </>
    );
}

export default Dashboard;