import { useSelector } from "react-redux";
import { selectCount, selectGene } from "../../../Slice/geneSlice";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

function GeneValidInfo() {
    const count = useSelector(selectCount);
    const genes = useSelector(selectGene);
    const invalid = genes.map((gene) => {
        if (gene.code === 2) {
            return gene.gene;
        }
        return null;
    });
    const synonym = genes.map((gene) => {
        if (gene.code === 1) {
            return gene.synonym;
        }
        return null;
    });

    const renderTooltipInvalidGenes = (props) => (
        <Tooltip id="tooltip" {...props}>
            <div className='tool'>
                The following gene symbols are invalid and will not be searched: {invalid.join(' ')}
            </div>
        </Tooltip>);

    const renderTooltipSynonymGenes = (props) => (
        <Tooltip id="tooltip" {...props}>
            <div className='tool'>
                The following genes will be searched with their alternative name: {synonym.join(' ')}
            </div>
        </Tooltip>);

    return (
        <div className="validity-info">
            <span className="numberValid">{count[0]}</span> <p>valid genes</p>
            {count[2] !== 0 ?
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltipInvalidGenes}>
                    <span style={{cursor:'pointer'}}><span className="numberInvalid">{count[2]}</span> <p>not valid genes</p></span>
                </OverlayTrigger> :
                <span><span className="numberInvalid">{count[2]}</span> <p>not valid genes</p></span>}
            {count[1] !== 0 ?
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltipSynonymGenes}>
                    <span style={{cursor:'pointer'}}><span className="numberSynonym">{count[1]}</span> <p>genes with an alternative name</p> </span>
                </OverlayTrigger> :
                <span><span className="numberSynonym">{count[1]}</span> <p>genes with an alternative name</p></span>}
        </div>
    );
}

export default GeneValidInfo;