import TooltipPass from '../TooltipPass';
import Password from '../Password';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { selectPassword, setPasswordInit } from '../../../../Slice/passwordSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectMessageProfileVal, setMessageProfileVal } from '../../../../Slice/messageProfileValidationSlice';
import { selectBearer } from '../../../../Slice/bearerSlice';
import { useState } from 'react';
import {Url} from '../../../../constants/global';

function ChangePassword(props) {
    let errPass, messagePassword;
    const dispatch = useDispatch();
    const messageValidation = useSelector(selectMessageProfileVal);
    const password = useSelector(selectPassword);
    const [open, setOpen] = useState(false);
    const logToken = useSelector(selectBearer);

    function reset() {
        props.val[1]({ ...props.val[0], password: false });
        dispatch(setPasswordInit());
    }

    function handleSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();
        if (!(password.old === '' && password.pass === '' && password.confirm === '')) {
            props.val[1]({ ...props.val[0], password: true });
            if (form.checkValidity() === false || errPass !== "") {
                event.stopPropagation();
            } else {
                putPassword();
                //invio i dati e attendo che mi confermino che la vecchia password corrisponde. (non aggiorno nessuno stato perché non ho la pass salvata)
            }
        }
    }

    //function to send data
    function putPassword() {
        const fetchPromise = fetch(Url + '/oliver/users/change-password', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${logToken}` },
            body: JSON.stringify({
                newPassword: password.pass,
                oldPassword: password.old
            })
        });
        fetchPromise
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 409) {
                        dispatch(setMessageProfileVal(messageValidation.map((err) => {
                            if (err.id === 'password') {
                                return { ...err, show: true, value: 'Incorrect old password!', type: 'error' };
                            } else {
                                return { ...err };
                            }
                        })));
                    }
                    throw new Error(`HTTP error: ${response.status}`); //da vedere se deve succedere altro
                } else {
                    dispatch(setMessageProfileVal(messageValidation.map((err) => {
                        if (err.id === 'password') {
                            return { ...err, show: true, value: 'Password successfully changed!', type: 'success' };
                        } else {
                            return { ...err };
                        }
                    })));
                    return response.json();
                }
            })
            .then(() => {
                props.val[1]({ ...props.val[0], password: false });
                dispatch(setPasswordInit());
            })
            .catch((error) => {
                console.error(`Could not get products: ${error}`);
            });
    }

    if (password.pass !== password.confirm) {
        errPass = "Passwords do not match!";
    } else if (password.old === password.pass && password.old !== '') {
        errPass = "New password must differs from the old!";
    } else {
        errPass = "";
    }

    messageValidation.map((message) => {
        if (message.id === 'password' && message.show === true) {
            if (message.type === 'error') {
                messagePassword = <p className='mb-2 errorMessage'>{message.value}</p>;
            } else {
                messagePassword = <p className='mb-2 successMessage'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5abeba"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" /></svg>
                    {message.value}</p>;
            }
        }
        return null;
    })

    return (
        <Form noValidate validated={props.val[0].password} onSubmit={(e) => { handleSubmit(e, 'password') }}><Row>
            <Form.Label className='profile-label' onClick={() => { setOpen({ ...open, password: !open.password }) }}>Change password
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#344d66"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87" /><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" /></svg>
            </Form.Label>
            {open.password ?
                <span className='sez'>
                    <Row className="mb-2 data">
                        <Col><label>OLD PASSWORD</label></Col>
                        <Col className="profile-pass"><Password />
                            <Form.Control.Feedback type="invalid" className='invalid'>Please enter your old password.</Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row className="mb-2 data">
                        <Col><label>NEW PASSWORD</label> <TooltipPass /></Col>
                        <Col className="profile-pass"><Password value='pass' />
                            <Form.Control.Feedback type="invalid" className='invalid'>Please enter a new valid password.</Form.Control.Feedback></Col>
                    </Row>
                    <Row className="mb-2 data">
                        <Col><label>CONFIRM PASSWORD</label></Col>
                        <Col className="profile-pass"><Password value='confirm' />
                            <span className='invalid-password'>{props.val[0].password ? errPass : null}</span></Col>
                    </Row>
                    <Row><Col className='no-wrap saveXcol'><Button className="mb-2 save" type='submit'>SAVE</Button>
                        <Button className="mb-2 cancel" type='reset' onClick={() => reset()}>X</Button></Col>
                        <Col> {messageValidation[1].show ? messagePassword : null}</Col>
                    </Row>
                </span> : null}
        </Row></Form>
    );
}

export default ChangePassword;