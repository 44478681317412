import Card from 'react-bootstrap/Card';
import { useDispatch, useSelector } from 'react-redux';
import {selectButtonResults, setButtonResults, setDefaultOrder} from '../../../../Slice/resultsSlice';
import './info.css';
import GenesInfo from './Genes/GenesInfo';
import GeneralInfo from './General/GeneralInfo';
import PhenotypesInfo from './Phenotypes/PhenotypesInfo';
import GeneInteractionInfo from './GeneInteraction/GeneInteractionInfo';

function DigenicInfo() {
    const selectedButton = useSelector(selectButtonResults);
    const dispatch = useDispatch();
    const buttonNames = ['general', 'genes', 'phenotypes', 'gene Interaction'];
    const components = {
        general: GeneralInfo,
        genes: GenesInfo,
        phenotypes: PhenotypesInfo,
        'gene Interaction': GeneInteractionInfo
    };
    const InfoPanel = components[selectedButton];

    const infoButtons = buttonNames.map((name) => {
        return <button key={name} type='button' className='info-button' onClick={() => {
            dispatch(setButtonResults(name));
            if(name === 'phenotypes') dispatch(setDefaultOrder());
        }
        } style={selectedButton === name ? { color: '#ffffff', backgroundColor: '#344d66' } : null}>{name.toUpperCase()}</button>
    })

    return (
        <Card className='info-result-card'>
            <Card.Body className='info-result-card-body'>
                <span className='info-buttons'>
                    {infoButtons}
                </span>
                <InfoPanel />
            </Card.Body>
        </Card>
    );
}

export default DigenicInfo;