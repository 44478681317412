import { useSelector } from "react-redux";
import { selectCombinations, selectIndexSelectedRow } from "../../../../../Slice/resultsSlice";
import Mechanism from "../../Mechanism";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PointerBar from "../PointerBar";
import TooltipMechanism from "./TooltipMechanism";
import './generalInfo.css';
import { useRef, useLayoutEffect, useState } from 'react';

function GeneralInfo() {
    const combinations = useSelector(selectCombinations);
    const index = useSelector(selectIndexSelectedRow);
    const colorLegend = [
        { value: 'low', color: '#ee8373' },
        { value: 'medium', color: '#ffc20e' },
        { value: 'significant', color: '#5abe99' },
        { value: 'very high', color: '#5abeba' }
    ];
    const predict = combinations[index].predictedDtype.split('/');
    const legend = {
        TD: 'True Digenic',
        DMD: 'Dual Molecular Diagnosis',
        CO: 'Composite',
        NA: 'Not Available'
    }
    const prediction = predict.length === 1 ? legend[predict[0]] : `${legend[predict[0]]} / ${legend[predict[1]]}`;
    const gene1 = `${combinations[index].combinationMembers[0].gene}:${combinations[index].combinationMembers[0].variantList[0].transcriptList[0].hgvsc}`;
    const gene2 = `${combinations[index].combinationMembers[1].gene}:${combinations[index].combinationMembers[1].variantList[0].transcriptList[0].hgvsc}`;
    const ref = useRef([]);
    const [isOverflown, setIsOverflown] = useState(Array(2).fill(false));
    const [width, setWidth] = useState(window.innerWidth); // check width size of the window
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };
    const [width2, setWidth2] = useState(false);

    const handleOpening = () => {
        setWidth2(!width2);
    }
    useLayoutEffect(() => {
        let element;
        let count1 = 0;
        setIsOverflown(prevState => prevState.map((item, j) => {
            element = ref.current[count1];
            if (element !== undefined && element !== null) {
                if (j === count1) {
                    if (element.offsetWidth === 0) { //to set the correct length when the section is opened (otherwise it is set to 0)
                        handleOpening();
                    }
                    const varia = element.offsetWidth < element.scrollWidth;
                    count1++;
                    return varia;
                } else {
                    count1++;
                    return item;
                }
            }
            return null;
        }));

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };

    }, [width, width2]);

    return (
        <div className="info-panel">
            <table className="info-table">
                <tbody>
                    <tr><td rowSpan={5} colSpan={2} className='general-col' id='mechanism'><Mechanism index={index} /></td>
                        <td colSpan={2} className='general-col'><span><p className="infoTable-label info-title">Digenic Mechanism</p></span></td>
                    </tr><tr>
                        <td className="col-label-general">
                            <p className="infoTable-label">Predicted digenic effect:</p>
                        </td><td><span>
                            <p className="infoTable-content" style={{ marginRight: '5px' }}>{prediction}</p>
                            <TooltipMechanism mech={combinations[index].predictedDtype}/> {/*da rivedere */}
                        </span>
                        </td>
                    </tr>
                    <tr><td colSpan={2}>
                        <span><p className="infoTable-label info-title">Impact on the prediction</p></span>
                    </td></tr>
                    <tr>
                        <td className="col-label-general">
                            <p className="infoTable-label">Variants Pathogenicity:</p>
                        </td><td><span>
                        {combinations[index].variantContribution!== null ? <PointerBar legend={colorLegend} value={combinations[index].variantContribution} />: <p className='infoTable-content'>Not Available</p>}
                        </span>  </td>
                    </tr>
                    <tr>
                        <td className="col-label-general">
                            <p className="infoTable-label">Phenotype similarity:</p>
                        </td><td><span>
                        {combinations[index].variantContribution!== null ? <PointerBar legend={colorLegend} value={combinations[index].phenotypeContribution} />: <p className='infoTable-content'>Not Available</p>}

                        </span> </td>
                    </tr>
                    <tr>
                        <td className='infoTable-content-gene1'>
                            <OverlayTrigger placement='bottom'  delay={{ show: 100, hide: 100 }}  overlay={isOverflown[0] ? <Tooltip className='custom-gene-tooltip' style={{position:"fixed"}} id='tooltip'><div className='tool'>{gene1}</div></Tooltip> : <></>}>
                                <p className='infoTable-content-gene1' style={isOverflown[0]? {cursor:"pointer"} : {cursor:"auto"}} ref={el => ref.current[0] = el}>{gene1}</p>
                            </OverlayTrigger></td><td className="infoTable-content-gene2">
                            <OverlayTrigger placement='bottom' delay={{ show: 100, hide: 100 }} overlay={isOverflown[1] ? <Tooltip className='custom-gene-tooltip' style={{position:"fixed"}} id='tooltip'><div className='tool'>{gene2}</div></Tooltip> : <></>}>
                                <p className="infoTable-content-gene2" style={isOverflown[1]? {cursor:"pointer"} : {cursor:"auto"}} ref={el => ref.current[1] = el}>{gene2}</p>
                            </OverlayTrigger>
                        </td>
                        <td className="col-label-general"><p className="infoTable-label">Gene interaction:</p>
                        </td><td><span>
                                                {combinations[index].variantContribution!== null ? <PointerBar legend={colorLegend} value={combinations[index].geneContribution} />: <p className='infoTable-content'>Not Available</p>}

                        </span> </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default GeneralInfo;