import { createSlice } from '@reduxjs/toolkit';

const initialState = { value: [], count: [0, 0, 0], placeholder: 'Search by gene' }; //count [valid, synonym, invalid]

export const geneSlice = createSlice({
    name: 'gene',
    initialState,
    reducers: {
        setGene: (state, action) => {
            state.value.push(action.payload);
            switch (action.payload.code) {
                case 0:
                    state.count[0]++;
                    break;
                case 1:
                    state.count[1]++;
                    break;
                case 2:
                    state.count[2]++;
                    break;
                default:
                    break;
            }
        },
        deleteGene: (state, action) => {
            state.value.splice(action.payload.index, 1);
            switch (action.payload.code) {
                case 0:
                    state.count[0]--;
                    break;
                case 1:
                    state.count[1]--;
                    break;
                case 2:
                    state.count[2]--;
                    break;
                default:
                    break;
            }
        },
        setPlaceholder: (state, action) => {
            state.placeholder = action.payload;
        },
        resetInitialValue: (state) => {
            state.value = [];
            state.count = [0, 0, 0];
            state.placeholder = 'Search by gene';
        }
    }
});

export const { setGene, deleteGene, resetInitialValue, setPlaceholder } = geneSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectGene = (state) => state.gene.value;
export const selectCount = (state) => state.gene.count;
export const selectPlaceholder = (state) => state.gene.placeholder;

export default geneSlice.reducer;