import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile, setShowProfile } from '../../../../Slice/modalVisibilitySlice';
import { selectUserData, setUserData } from '../../../../Slice/userDataSlice';
import '../Modal.css';
import { useState } from 'react';
import { setPasswordInit } from '../../../../Slice/passwordSlice';
import { setMessageProfileVal, selectMessageProfileVal, setMessageProfileValInit } from '../../../../Slice/messageProfileValidationSlice';
import './Profile.css';
import ChangePersonalInfo from './ChangePersonalInfo';
import ChangeEmail from './ChangeEmail';
import ChangeAff from './ChangeAff';
import ChangePassword from './ChangePassword';

function Profile() {
  const show = useSelector(selectProfile);
  const dispatch = useDispatch();
  const initialVal = { personalInfo: false, email: false, country: false, password: false };
  const [validated, setValidated] = useState(initialVal);
  const data = useSelector(selectUserData);
  const [edit, setEdit] = useState({ firstname: "", lastname: "", user: "", email: "", aff: "" });
  const [country1, setCountry1] = useState(data.country);
  const messageValidation = useSelector(selectMessageProfileVal);

  const handleClose = () => {
    dispatch(setShowProfile(false));
    dispatch(setPasswordInit());
    setEdit({ firstname: "", lastname: "", user: "", email: "", aff: "" });
    setCountry1(data.country); //per non far visualizzare il reset mentre si chiude la modal
    dispatch(setUserData({ ...data, firstname: "", lastname: "", email: "", aff: "", country: "" }));
    dispatch(setMessageProfileValInit());
    setValidated(initialVal);
  };

  function handleChange(id) {
    dispatch(setMessageProfileVal(messageValidation.map((err) => {
      if (err.id === id) {
        return { ...err, show: false, value: '', type: '' };
      } else {
        return { ...err };
      }
    })));
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" className='modal' dialogClassName="modal-90w">
        <Modal.Header>
          <Modal.Title className='title'>
            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#5abeba"><g><path d="M0,0h24v24H0V0z" fill="none" /></g><g><g><path d="M4,18v-0.65c0-0.34,0.16-0.66,0.41-0.81C6.1,15.53,8.03,15,10,15c0.03,0,0.05,0,0.08,0.01c0.1-0.7,0.3-1.37,0.59-1.98 C10.45,13.01,10.23,13,10,13c-2.42,0-4.68,0.67-6.61,1.82C2.51,15.34,2,16.32,2,17.35V20h9.26c-0.42-0.6-0.75-1.28-0.97-2H4z" /><path d="M10,12c2.21,0,4-1.79,4-4s-1.79-4-4-4C7.79,4,6,5.79,6,8S7.79,12,10,12z M10,6c1.1,0,2,0.9,2,2s-0.9,2-2,2 c-1.1,0-2-0.9-2-2S8.9,6,10,6z" /><path d="M20.75,16c0-0.22-0.03-0.42-0.06-0.63l1.14-1.01l-1-1.73l-1.45,0.49c-0.32-0.27-0.68-0.48-1.08-0.63L18,11h-2l-0.3,1.49 c-0.4,0.15-0.76,0.36-1.08,0.63l-1.45-0.49l-1,1.73l1.14,1.01c-0.03,0.21-0.06,0.41-0.06,0.63s0.03,0.42,0.06,0.63l-1.14,1.01 l1,1.73l1.45-0.49c0.32,0.27,0.68,0.48,1.08,0.63L16,21h2l0.3-1.49c0.4-0.15,0.76-0.36,1.08-0.63l1.45,0.49l1-1.73l-1.14-1.01 C20.72,16.42,20.75,16.22,20.75,16z M17,18c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S18.1,18,17,18z" /></g></g></svg>
            Profile</Modal.Title>
          <Button onClick={handleClose} id='close'>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z" /><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
          </Button>
        </Modal.Header>
        <Modal.Body className='body'>
          <Container>
            <ChangePersonalInfo handleChange={handleChange} edit={[edit, setEdit]} val={[validated, setValidated]} />
            <ChangeEmail handleChange={handleChange} edit={[edit, setEdit]} val={[validated, setValidated]} />
            <ChangeAff handleChange={handleChange} edit={[edit, setEdit]} val={[validated, setValidated]} country={[country1, setCountry1]} />
            <ChangePassword val={[validated, setValidated]} />
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Profile;