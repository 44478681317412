import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './HeaderBar.css';
import Login from './Modal/Login';
import SignUp from './Modal/SignUp';
import RegistrationSuccess from './Modal/RegistrationSuccess';
import NavBarNotLog from './NavBarNotLog';
import NavBarLog from './NavBarLog';
import { selectLog } from '../../Slice/logSlice';
import logo from '../../Images/Oliverlogo-DarkBackground.png';
import MailForgotSent from './Modal/MailForgotSent';
import Profile from './Modal/Profile/Profile';
import { useDispatch, useSelector } from 'react-redux';
import { setInitType } from '../../Slice/typeSlice';
import { setSearch } from '../../Slice/searchBarSlice';
import { setShowExample } from '../../Slice/modalVisibilitySlice';
import { resetWarning } from '../../Slice/warningSlice';
import { resetInitialValue } from '../../Slice/geneSlice';
import { resetInitialValuePh } from '../../Slice/phenotypeSlice';
import { setClearTimer } from '../../Slice/timerSlice';
import { resetResults } from '../../Slice/resultsSlice';
import {resetLoader} from "../../Slice/loaderSlice";

function HeaderBar() {
    const logged = useSelector(selectLog);
    let nav;
    const dispatch = useDispatch();

    function click() {
        dispatch(setInitType());
        dispatch(setSearch(''));
        dispatch(setShowExample(false));
        dispatch(resetWarning());
        dispatch(resetInitialValue());
        dispatch(resetInitialValuePh());
        dispatch(setClearTimer());
        dispatch(resetResults());
        dispatch(resetLoader());
    }

    if (logged) {
        nav = <NavBarLog />;
    } else {
        nav = <NavBarNotLog />;
    }

    return (
        <Row className='headerBar'>
            <Col className='DIVAs-logo'>
                <img src={logo} alt="logo" onClick={click} />
            </Col>
            {nav}
            <Login />
            <MailForgotSent />
            <SignUp />
            <Profile />
            <RegistrationSuccess />
        </Row>
    );
}

export default HeaderBar;