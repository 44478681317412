import './geneInteraction.css';
import '../../../../loader/loader.css';
import { useSelector } from "react-redux";
import { selectCombinations, selectIndexSelectedRow } from "../../../../../Slice/resultsSlice";
import { useEffect, useState } from 'react';
import { getSTRING } from './StringFunction';

function GeneInteractionInfo() {
    const combinations = useSelector(selectCombinations);
    const index = useSelector(selectIndexSelectedRow);
    let linkGeneMania = 'https://genemania.org/search/homo-sapiens/';
    let genes = [];
    const [stringScore, setStringScore] = useState('');

    useEffect(
        () => {
            async function getSTRINGNetwork() {
                try {
                    const response = await fetch('https://string-db.org/api/json/network?' + new URLSearchParams({ 'species': '9606', 'identifiers': ''.concat(genes.join('%0d')) }), {
                        method: 'GET',
                    });
                    if (!response.ok) {
                        throw new Error(`HTTP error: ${response.status}`);
                    } else {
                        const data = await response.json();
                        setStringScore(data);
                    }
                } catch (error) {
                    console.error(`Could not get products: ${error}`);
                };
            }
            getSTRINGNetwork();
            getSTRING('https://string-db.org', {
                'species': '9606',
                'identifiers': genes,
                'network_flavor': 'confidence',
                'caller_identity': 'www.awesome_app.org'
            })
        }, []);

    combinations[index].combinationMembers.map((gene, i) => {
        genes[i] = gene.gene;
        return null;
    })
    linkGeneMania = linkGeneMania.concat(genes.join('/'))

    return (
        <div className="info-panel">
            <div>
                <span><p className="infoTable-label">GeneMANIA:</p> <a className="img-link" href={linkGeneMania} target='_blank' rel='noreferrer'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#344d66" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                        <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                    </svg>
                </a></span></div>
            <div name='STRING_network'>     
                <div id="stringEmbedded">
                    <span className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                        <div className="bounce4"></div>
                    </span>
                </div>
                {stringScore.length !== 0 ?
                        <table style={{height:'fit-content'}} name='score-table'>
                        <thead><tr><th>Evidence suggesting a functional link:</th></tr></thead>
                        <tbody>
                            {stringScore[0].nscore <= 0 ? null : <tr><td>Neighborhood in the Genome</td></tr>}
                            {stringScore[0].fscore <= 0 ? null : <tr><td>Gene Fusions</td></tr>}
                            {stringScore[0].pscore <= 0 ? null : <tr><td>Cooccurence Across Genomes</td></tr>}
                            {stringScore[0].ascore <= 0 ? null : <tr><td>Co-Expression</td></tr>}
                            {stringScore[0].escore <= 0 ? null : <tr><td>Experimental/Biochemical Data</td></tr>}
                            {stringScore[0].dscore <= 0 ? null : <tr><td>Association in Curated Databases</td></tr>}
                            {stringScore[0].tscore <= 0 ? null : <tr><td>Co-Mentioned in Pubmed Abstracts</td></tr>}
                        </tbody></table> : <div><b>Evidence suggesting a functional link:</b><p>There is no significant evidence</p></div>
                    }
            </div>
        </div>
    );
}

export default GeneInteractionInfo;