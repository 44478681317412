import TooltipUser from '../TooltipUser';
import { Row, Col } from 'react-bootstrap';
import { selectUserData, setUserData } from '../../../../Slice/userDataSlice';
import Form from 'react-bootstrap/Form';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import { selectMessageProfileVal, setMessageProfileVal } from '../../../../Slice/messageProfileValidationSlice';
import { selectBearer } from '../../../../Slice/bearerSlice';
import {Url} from '../../../../constants/global';


function ChangePersonalInfo(props) {
    let messagePersonalInfo;
    const data = useSelector(selectUserData);
    const dispatch = useDispatch();
    const messageValidation = useSelector(selectMessageProfileVal);
    const [open, setOpen] = useState(false);
    const logToken = useSelector(selectBearer);

    function reset() {
        props.val[1]({ ...props.val[0], personalInfo: false });
        props.edit[1]({ ...props.edit[0], firstname: '', lastname: '', user: '' });
    }

    function handleSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();
        props.val[1]({ ...props.val[0], personalInfo: true });
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            if (!((props.edit[0].firstname === '' || data.firstname === props.edit[0].firstname) && (props.edit[0].lastname === '' || data.lastname === props.edit[0].lastname) && (props.edit[0].user === '' || data.user === props.edit[0].user))) {
                putInfo();
            }
        }
    }

    //function to send data
    function putInfo() {
        const fetchPromise = fetch(Url + '/oliver/users/change-fullname-username', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${logToken}` },
            body: JSON.stringify({
                firstName: props.edit[0].firstname,
                lastName: props.edit[0].lastname,
                username: props.edit[0].user
            })
        });
        fetchPromise
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 409) {
                        dispatch(setMessageProfileVal(messageValidation.map((err) => {
                            if (err.id === 'personalInfo') {
                                return { ...err, show: true, value: 'Username not available!', type: 'error' };
                            } else {
                                return { ...err };
                            }
                        })));
                    }
                    throw new Error(`HTTP error: ${response.status}`); //da vedere se deve succedere altro
                } else {
                    dispatch(setMessageProfileVal(messageValidation.map((err) => {
                        if (err.id === 'personalInfo') {
                            return { ...err, show: true, value: 'Personal info successfully changed!', type: 'success' };
                        } else {
                            return { ...err };
                        }
                    })));
                    return response.json();
                }
            })
            .then((data1) => {
                dispatch(setUserData({ ...data, firstname: data1.firstName, lastname: data1.lastName, user: data1.username }));
                props.val[1]({ ...props.val[0], personalInfo: false });
                props.edit[1]({ ...props.edit[0], firstname: '', lastname: '', user: '' });
            })
            .catch((error) => {
                console.error(`Could not get products: ${error}`);
            });
    }

    messageValidation.map((message) => {
        if (message.id === 'personalInfo' && message.show === true) {
            if (message.type === 'error') {
                messagePersonalInfo = <p className='mb-2 errorMessage'>{message.value}</p>;
            } else {
                messagePersonalInfo = <p className='mb-2 successMessage'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5abeba"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" /></svg>
                    {message.value}</p>;
            }
        }
        return null;
    })

    return (
        <Form noValidate validated={props.val[0].personalInfo} onSubmit={(e) => { handleSubmit(e, 'personalInfo') }}><Row>
            <Form.Label className='profile-label' onClick={() => { setOpen(!open) }}>Change personal info
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#344d66"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87" /><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" /></svg>
            </Form.Label>
            {open ?
                <span className='sez'>
                    <Row className='data mb-2'>
                        <Col><label>FIRST NAME</label></Col>
                        <Col><input type="text" id='firstname' placeholder={data.firstname} minLength='3' pattern='[A-Za-zÀ-ÖØ-öø-ÿ\s\.]*' value={props.edit[0].firstname} onChange={(e) => { props.edit[1]({ ...props.edit[0], firstname: e.target.value }); props.handleChange('personalInfo') }} />
                            <Form.Control.Feedback type="invalid" className='invalid'>Please enter your name.</Form.Control.Feedback></Col>
                    </Row>
                    <Row className='data mb-2'>
                        <Col><label>LAST NAME</label></Col>
                        <Col><input type="text" id='lastname' placeholder={data.lastname} minLength='3' pattern="[A-Za-zÀ-ÖØ-öø-ÿ\s\.']*" value={props.edit[0].lastname} onChange={(e) => { props.edit[1]({ ...props.edit[0], lastname: e.target.value }); props.handleChange('personalInfo') }} />
                            <Form.Control.Feedback type="invalid" className='invalid'>Please enter your last name.</Form.Control.Feedback></Col>
                    </Row>
                    <Row className='data mb-2'>
                        <Col><span><label>USERNAME</label> <TooltipUser /></span></Col>
                        <Col><input type="text" id='user' placeholder={data.user} pattern='[A-Za-z0-9À-ÖØ-öø-ÿ\.]*' maxLength={32} value={props.edit[0].user} onChange={(e) => { props.edit[1]({ ...props.edit[0], user: e.target.value }); props.handleChange('personalInfo') }} />
                            <Form.Control.Feedback type="invalid" className='invalid'>Please enter a valid username.</Form.Control.Feedback></Col>
                    </Row>
                    <Row><Col className='no-wrap saveXcol'><Button className="mb-2 save" type='submit'>SAVE</Button>
                        <Button className="mb-2 cancel" type='reset' onClick={() => reset()}>X</Button>
                    </Col>
                        <Col> {messageValidation[0].show ? messagePersonalInfo : null}</Col>
                    </Row>
                </span> : null}
        </Row></Form>
    );
}

export default ChangePersonalInfo;