import { Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { setChangelog, selectChangelog } from '../../../Slice/modalVisibilitySlice';
import './Modal.css';

function Changelog() {
    const show = useSelector(selectChangelog);
    const dispatch= useDispatch();

    const handleClose = () => {
        dispatch(setChangelog(false));
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" className='modal' size='lg'>
                <Modal.Header>
                    <Modal.Title className='title'>
                        <svg style={{marginBottom:'2px'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path fill='#344D66' d="M75 75L41 41C25.9 25.9 0 36.6 0 57.9V168c0 13.3 10.7 24 24 24H134.1c21.4 0 32.1-25.9 17-41l-30.8-30.8C155 85.5 203 64 256 64c106 0 192 86 192 192s-86 192-192 192c-40.8 0-78.6-12.7-109.7-34.4c-14.5-10.1-34.4-6.6-44.6 7.9s-6.6 34.4 7.9 44.6C151.2 495 201.7 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C185.3 0 121.3 28.7 75 75zm181 53c-13.3 0-24 10.7-24 24V256c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65V152c0-13.3-10.7-24-24-24z"/></svg>

                        Changelog</Modal.Title>
                    <Button onClick={handleClose} id='close'>
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z" /><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>

                        {/*<h5>Version 1.1 (October 25, 2023)</h5>*/}
                        {/*    <p><svg style={{paddingBottom:'2px'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path fill='#344D66' d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>*/}
                        {/*        New stuff 1.</p>*/}
                        {/*    <p><svg style={{paddingBottom:'2px'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path fill='#344D66' d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>*/}
                        {/*        New stuff 2.</p>*/}
                        {/*<hr/>*/}
                            <h5>Version 1.0 (September 30, 2023)</h5>
                            <div><p className='new-tag'>New</p>
                            First release of the platform.</div>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Changelog;