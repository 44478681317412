import './Gene.css';
import DeleteBubble from '../DeleteBubble';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

function Gene(props) {

    const renderTooltipGene = (e, value) => (
        <Tooltip id="tooltip" {...e}>
            <div className='tool'>
                {value}
            </div>
        </Tooltip>);

    switch (props.value.code) {
        case 0:
            return (
                <div className='gene-container'>
                    <div className="buttonValid">
                        <span className="validGene">
                            <p className="gene">{props.value.gene}</p>
                        </span>
                    </div>
                    <DeleteBubble type='gene' value={props.value} index={props.index}/>
                </div>
            );
        case 1:
            const str = 'This gene will be searched using its synonym ' + props.value.gene;
            return (
                <div className='gene-container'>
                    <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={(e) => renderTooltipGene(e, str)}>
                        <div className="buttonSynonym">
                            <span className="synonymGene">
                                <p className="gene">{props.value.synonym}</p>
                            </span>
                        </div>
                    </OverlayTrigger>
                    <DeleteBubble type='gene' value={props.value} index={props.index}/>
                </div>
            );
        case 2:
            return (
                <div className='gene-container'>
                    <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={(e) => renderTooltipGene(e, "This gene symbol is not valid")}>
                        <div className="buttonInvalid">
                            <span className="invalidGene">
                                <p className="gene">{props.value.gene}</p>
                            </span>
                        </div>
                    </OverlayTrigger>
                    <DeleteBubble type='gene' value={props.value} index={props.index}/>
                </div>
            );
        default:
            break;
    }
}

export default Gene;