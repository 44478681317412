import '../Search.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectSearch, setSearch, selectTriggerInput, selectUpdateFocus, selectSearching, startSearching } from '../../../Slice/searchBarSlice';
import { useLayoutEffect, useRef, useState, useEffect } from 'react';
import { setPhenotype, selectPhenotype, setPlaceholderPh, selectPlaceholder, setPhenotypeHints, selectPhenotypeHints, selectShowHint, setShowHint, selectCount } from '../../../Slice/phenotypeSlice';
import { setTimer } from '../../../Slice/timerSlice';
import PhenotypeList from './PhenotypeList';
import { phenotypeHpoValidation, validatePhenotypesT, getPhenotypesCombination } from '../validationSearchFunction/phenotypeValidation'
import { selectShowResults, setCombinations, setShowResults } from '../../../Slice/resultsSlice';
import { selectBearer } from '../../../Slice/bearerSlice';
import { setShowLogin } from '../../../Slice/modalVisibilitySlice';
import { setErrModalVal } from '../../../Slice/errModalValidationSlice';
import {setLoader} from "../../../Slice/loaderSlice";

function PhenotypeSearch() {
    const searchText = useSelector(selectSearch);
    const dispatch = useDispatch();
    const ref = useRef(null);
    const inputRef = useRef(null);
    const hintRef = useRef([]);
    const [width, setWidth] = useState(0);
    const placeholder = useSelector(selectPlaceholder);
    const phenotypeInfo = useSelector(selectPhenotype);
    const timerRef = useRef();
    const nPhenotype = 5; //max number of phenotype that we can search
    const [disabledInput, setDisabled] = useState(false);
    const [isPaste, setPaste] = useState(false);
    const [startValidation, setStartValidation] = useState({ HPO_id: false, text: false });
    const showHint = useSelector(selectShowHint);
    const phenotypeHints = useSelector(selectPhenotypeHints);
    const token = useSelector(selectBearer);
    let hint;
    const showRes = useSelector(selectShowResults);
    const triggerRef= useSelector(selectTriggerInput);
    const counters = useSelector(selectCount);
    const exampleFocus = useSelector(selectUpdateFocus);
    const searching = useSelector(selectSearching);

    useLayoutEffect(() => {
        setWidth(ref.current.offsetWidth);
        if (ref.current.offsetWidth === 16) {
            dispatch(setPlaceholderPh("Search by phenotype"));
        }
        if (phenotypeInfo.length >= nPhenotype || searching) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
        if (!searching){
            dispatch(setLoader(false));
        }
    }, [phenotypeInfo, searching]);

    useEffect(()=>{
        dispatch(setShowHint(false));
        if(searchText.length>2){
            if(!searchText.includes('HP:')){
                setStartValidation({ HPO_id: false, text: true });
                paste(); 
                handlechange({target:{value:searchText}}, true);
            } else {
                setPaste(false);
                setStartValidation({ HPO_id: true, text: false });
            }
        }
    }, [triggerRef]);

    useEffect(()=>{
        inputRef.current.focus();
    }, [exampleFocus, counters]);

    const timerFunction = (time) => {
        timerRef.current = setTimeout(() => {
            if (startValidation.HPO_id) {
                validatePhenotypesHpo(searchText);
            } else if (startValidation.text) {
                validatePhenotypesText(searchText);
            }
        }, time)
        dispatch(setTimer(timerRef.current));
    }

    const clearTimerFunction = () => {
        clearTimeout(timerRef.current);
    }

    async function handlechange(e, ex) {
        dispatch(setSearch(e.target.value));
        if (e.target.value.trimStart().length < 3) {
            setStartValidation({ HPO_id: false, text: false });
        }
        if (isPaste || ex) {
            if (e.target.value.trimStart().length > 2) {
                if (e.target.value.toUpperCase().trimStart().startsWith('HP:')) {
                    await validatePhenotypesHpo(e.target.value);
                    inputRef.current.focus();
                } else {
                    validatePhenotypesText(e.target.value);
                }
            }
        }
    }

    function keyup(e) {
        if (!isPaste) {
            clearTimerFunction();
            if (e.key !== 'Enter') {
                if (startValidation.HPO_id && e.key !== ' ') {
                    timerFunction(5000);
                } else if (startValidation.text) {
                    timerFunction(1000);
                }
            }
        }
    }

    if (searchText.trimStart().length > 2 && !(startValidation.HPO_id || startValidation.text)) {
        if (searchText.toUpperCase().trimStart().startsWith('HP:')) {
            setStartValidation({ HPO_id: true, text: false });
        } else {
            setStartValidation({ HPO_id: false, text: true });
        }
    }

    async function keydown(e) {
        clearTimerFunction();
        setPaste(false);
        if(e.key === 'ArrowDown' && phenotypeHints.length !== 0 && showHint){ //to scroll the hints list
            hintRef.current[0].focus();
        } else{
            dispatch(setShowHint(false));
            if (e.key === 'Enter') {
                if(!startValidation.text){ //if is not text or contains less than 3 characters start the search
                    let phenoList = []; //list of valid phenotypes
                    dispatch(startSearching(true));
                    phenotypeInfo.map((phenotype) => {
                        if (phenotype.code !== 2) {
                            phenoList.push(phenotype.hpo_id);
                        }
                        return null;
                    });
                    //search without validate first HPO id
                    if (startValidation.HPO_id) {
                        const phen = await validatePhenotypesHpo(searchText);
                        for (const element of phen) {
                            if (element.code === 0 || element.code === 1) {
                                phenoList.push(element.hpo_id);
                            }
                        }
                    } else {
                        dispatch(setSearch(''));
                    }
                    //entro solo se mando dei fenotipi, se invio una stringa vuota o dei fenotipi invalid non faccio call al back-end
                    if (phenoList.length !== 0) {
                        if (token !== '') {
                            dispatch(setLoader(true));
                            const combinations = await getPhenotypesCombination(phenoList, token);
                            if (combinations !== null) {
                                dispatch(setShowResults(true));
                                dispatch(setCombinations(combinations));
                            } else {
                                dispatch(setShowResults(!showRes)); //to force rerender
                            }
                        } else {
                            e.preventDefault();
                            dispatch(setShowLogin(true));
                            dispatch(setErrModalVal({ show: true, value: 'In order to search you need to login.' }));
                        }
                    } 
                    dispatch(startSearching(false));
                } else { //start text search
                    validatePhenotypesText(searchText);
                }
            } else if (e.key === ' ' && searchText.replace(/\s+/g, '') !== '') {
                if (startValidation.HPO_id) {
                    validatePhenotypesHpo(searchText);
                }
            }
        }
    }

    function paste() {
        clearTimerFunction();
        setPaste(true);
    }

    // valido il fenotipo
    async function validatePhenotypesHpo(searchText) {
        dispatch(setLoader(true));
        setDisabled(true);
        const validate = await phenotypeHpoValidation(searchText, nPhenotype, phenotypeInfo.length);
        //0 valid, 1 obsolete, 2 invalid
        for (const element of validate) {
            switch (element.code) {
                case 0:
                    dispatch(setPhenotype(element));
                    dispatch(setPlaceholderPh(""));
                    dispatch(setSearch(""));
                    setStartValidation({HPO_id: false, text: false});
                    break;
                case 1:
                    dispatch(setPhenotype({code: element.code, hpo_id: element.hpo_id, obsolete: searchText})); //da sistemare
                    dispatch(setPlaceholderPh(""));
                    dispatch(setSearch(""));
                    setStartValidation({HPO_id: false, text: false});
                    break;
                case 2:
                    dispatch(setPhenotype(element));
                    dispatch(setPlaceholderPh(""));
                    dispatch(setSearch(""));
                    setStartValidation({ HPO_id: false, text: false });
                    break;
                default:
                    break;
            }
        }
        return validate;
    }

    //manage the navigation with keyboard
    function hintKeyDown(e, i){
        switch (e.key){
            case 'Enter':
                clickNamePh(i);
                break;
            case 'ArrowDown':
                if(hintRef.current.length-1>i){
                    hintRef.current[i+1].focus();
                }
                break;
            case 'ArrowUp':
                if(i>0){
                    hintRef.current[i-1].focus();
                } else if(i===0){
                    inputRef.current.focus();
                }
                break;
            default:
                break;
        }
    }

    function hintMouseEvent(e, i){
        hintRef.current[i].focus();
    }

    //validate and text search results
    async function validatePhenotypesText(searchText) {
        let validate = await validatePhenotypesT(searchText);
        validate.phenotypes= validate.phenotypes.map(obj => ({ ...obj, code: 0 }));
        dispatch(setSearch(validate.search));
        dispatch(setPhenotypeHints(validate.phenotypes));
        dispatch(setShowHint(true));
    }

    function clickNamePh(i) {
        dispatch(setPhenotype(phenotypeHints[i]));
        dispatch(setPlaceholderPh(""));
        dispatch(setSearch(""));
        setStartValidation({ HPO_id: false, text: false });
        dispatch(setShowHint(false));
    }

    if (showHint) {
        if (phenotypeHints.length !== 0) {
            let hint_rows = phenotypeHints.map((ph, i) => {
                return <p key={i} className='phenotype-name' onClick={() => { clickNamePh(i) }} onKeyDown={(e)=>{ hintKeyDown(e, i) }} onMouseMoveCapture={(e)=>{ hintMouseEvent(e, i)}} ref={el => hintRef.current[i] = el} tabIndex={i} >{ph.name}<br /></p>
            })
            hint = <div className='hint'> {hint_rows}</div>;
        } else {
            hint = <div className='hint'> <p className='no-phenotype'>No terms found!</p></div>
        }
    }

    return (
        <>
            <span className='searchPhenotype-container' style={{ position: 'relative' }}>
                <span style={{ position: 'absolute' }} className="phenotypeRef" ref={ref}><PhenotypeList /></span>
                <input type="text" name="input-search" className='inputSearchPh' disabled={disabledInput} placeholder={placeholder} value={searchText} onChange={handlechange} style={{ paddingLeft: width }} onKeyUp={keyup} onKeyDown={keydown} onPaste={paste} ref={inputRef} autoComplete="off"/>
            </span>
            {showHint ? hint : null}
        </>
    );
}

export default PhenotypeSearch;