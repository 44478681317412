import { useState } from 'react';
import './HelpCenter.css';

function ResultsHelper() {
    const [open, setOpen] = useState(true);
    const [rotate, setRotate] = useState(null);

    return (<><div>
            <h6  className='titleHelpCenter' onClick={() => {setOpen(!open);
                if(rotate !==null) {
                    setRotate(!rotate);
                }else{
                    setRotate(true);
                }
            }} >Results visualization
                <svg className={rotate !== null ? (rotate ? 'svgClickOpen' : ''): ''} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#344d66"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87" /><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" /></svg>
            </h6>

            <div className={open ? 'show' : 'notshow'}>
                When performing a research, retrieved combinations are shown in a tabular structure.
                On each line, a preview of the combination is shown, including gene symbols, variants'
                HGVS and zygosity, predicted digenic mechanism and a preview of the associated
                phenotypes. By clicking on each row, it is possible to expand the combination details.
            </div>
    </div></>);
}

export default ResultsHelper;