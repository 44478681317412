import { createSlice } from '@reduxjs/toolkit';

const initialState={value: '', ref:false, focus: false, searching: false};

export const searchBarSlice = createSlice({
    name: 'searchbar',
    initialState,
    reducers: {
        setSearch: (state, action) => {
            state.value= action.payload;
        },
        triggerInputPaste: (state, action) => { //per triggerare il paste event cliccando sull'esempio dei fenotipi
            state.ref= action.payload;
        },
        updateFocus: (state, action) => { //per aggiornare il focus dopo aver selezionato un esempio
            state.focus= action.payload;
        }, 
        startSearching:(state, action) => { //per capire se si sta cercando o solo validando
            state.searching= action.payload;
        }, 
    }
});

export const { setSearch, triggerInputPaste, updateFocus, startSearching } = searchBarSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectSearch = (state) => state.searchbar.value;
export const selectTriggerInput = (state) => state.searchbar.ref;
export const selectUpdateFocus = (state) => state.searchbar.focus;
export const selectSearching = (state) => state.searchbar.searching;

export default searchBarSlice.reducer;