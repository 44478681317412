import {Url} from '../../../constants/global';
export async function geneValidation(strings_array_in, nGene, geneVal) { //nGene= max num of genes that accept, geneVal= number of genes already validated
    let gene = [];
    let str_vett = [];
    strings_array_in = strings_array_in.toUpperCase();
    strings_array_in = strings_array_in.split(' ');
    // strings_array_in = strings_array_in.filter((str) => str !== "") 
    for (let i = 0; i < strings_array_in.length; i++) {
        if (strings_array_in[i].split(',').length !== 1) {
            str_vett = str_vett.concat(strings_array_in[i].split(','));
        } else {
            str_vett = str_vett.concat(strings_array_in[i].split('\t')); //add tab if there is a copy and paste from excel
        }
    }
    strings_array_in = str_vett.filter((str) => str !== "")//delete empty string
    strings_array_in = strings_array_in.splice(0, nGene - geneVal); //valid only as many genes as are missing to reach the max
    for (let i = 0; i < strings_array_in.length; i++) {
        if (strings_array_in[i].length > 1 && strings_array_in[i].length < 33 && /^[a-zA-Z0-9-]+$/.test(strings_array_in[i])) {
            gene[i] = await validateGene(strings_array_in[i]);
            if(gene[i].code===1){
                gene[i].synonym= strings_array_in[i];
            }
        }
        else {
            gene[i] = { code: 2, gene: strings_array_in[i] }; //not valid
        }
    }
    return gene;
}

async function validateGene(gene) {
    try {
        const response = await fetch(Url + '/oliver/genes/validate-gene?' + new URLSearchParams({ name: gene }), {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
            throw new Error(`HTTP error: ${response.status}`);
        } else {
            const data = await response.json();
            return { code: data.status_code, gene: data.name };
        }

    } catch (error) {
        console.error(`Could not get products: ${error}`);
    }
}

//geneList sarà un array con i nomi dei geni da cercare
export async function getGeneCombination(geneList, logToken) {
    try {
        const response = await fetch(Url + '/oliver/combination/gene-search?' + new URLSearchParams({ genesToSearch: geneList }), {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${logToken}` }
        });
        if (!response.ok) {
            if (response.status === 500) {
                return null;
            }
            throw new Error(`HTTP error: ${response.status}`);
        } else {
            const data = await response.json();
            return data;
            //da vedere come trattare i dati ricevuti
            // return { code: data.status_code, gene: data.name };
        }
    } catch (error) {
        console.error(`Could not get products: ${error}`);
    }
}