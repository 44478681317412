import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VariantSearch from './variantSearch/VariantSearch';
import GeneSearch from './geneSearch/GeneSearch';
import PhenotypeSearch from './phenotypeSearch/PhenotypeSearch';
import './SearchPanel.css';
import SearchButton from './searchButton/SearchButton';
import TypeSearch from './typeSearch/TypeSearch';
import ExampleButton from './example/ExampleButton';
import { useSelector } from 'react-redux';
import { selectType } from '../../Slice/typeSlice';
import { selectWarning } from '../../Slice/warningSlice';
import GeneValidInfo from './geneSearch/GeneValidInfo';
import PhenotypeValidInfo from './phenotypeSearch/PhenotypeValidInfo';
import { selectShowResults } from '../../Slice/resultsSlice';
import SearchResults from './results/SearchResults';

function SearchPanel() {
    const type = useSelector(selectType);
    let pag = <VariantSearch />;
    const warning = useSelector(selectWarning);
    let info;
    const showResults = useSelector(selectShowResults);

    switch (type.typeSearch) {
        case 'variant':
            pag = <VariantSearch />;
            info = <Row className='warning'> <Col> <span style={{ paddingRight: '5px' }}>{warning.show ? warning.value : null}</span></Col></Row>;
            break;
        case 'gene':
            pag = <GeneSearch />;
            info = <Row className='row-gene-info'><Col> <GeneValidInfo /> </Col></Row>
            break;
        case 'phenotype':
            pag = <PhenotypeSearch />;
            info = <Row className='warning'> <Col> <PhenotypeValidInfo /></Col></Row>;
            break;
        default:
            break;
    }

    return (
        <Row>
            <Container className='searchPanel' style={showResults ? { paddingTop: '1.5%' } : null}>
                <Row>
                    <Col md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }}>
                        <TypeSearch />
                        {pag}
                        <SearchButton />
                    </Col>
                </Row>
                {info}
                {showResults ? <Row className='row-results'><Col md={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }}><SearchResults /></Col></Row> : <Row><Col md={{ span: 1, offset: 9 }}> <ExampleButton /></Col></Row>}
            </Container>
        </Row>
    );
}

export default SearchPanel;