import { createSlice } from '@reduxjs/toolkit';

//vengono riempiti al momento del login e riempiono i campi di profile
const initialState={value:''}; //da togliere il valore dello user

export const bearerSlice = createSlice({
    name: 'bearer',
    initialState,
    reducers: {
        setBearer: (state, action) => {
            state.value= action.payload;
        }
    }
});

export const { setBearer } = bearerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectBearer = (state) => state.bearer.value;

export default bearerSlice.reducer;