import Card from 'react-bootstrap/Card';
import DigenicCombinations from './Digenic/DigenicCombinations';
import './searchResults.css'
import { useSelector } from 'react-redux';
import { selectCombinationsCount } from '../../../Slice/resultsSlice';
import NoResults from './NoResults';

function SearchResults() {
    const count = useSelector(selectCombinationsCount)

    return (
        <Card className='results-card'>
            <Card.Header className='results-card-header'>
                <span className='results-title'><p>Matching combinations </p> <p style={{ color: 'rgba(52,77,102,0.4)' }}>({count})</p></span>
            </Card.Header>
            <Card.Body className='results-card-body'>
                {count === 0 ? <NoResults /> : <DigenicCombinations />}
            </Card.Body>
        </Card>
    );
}

export default SearchResults;