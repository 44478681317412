import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import {selectCombinations, selectIndexSelectedRow, setOnlyCombinations} from "../../../../../Slice/resultsSlice";
import PointerBar from "../PointerBar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSort, faSortDown, faSortUp} from "@fortawesome/free-solid-svg-icons";

function PhenotypesInfo() {
    const combinations = useSelector(selectCombinations);
    const index = useSelector(selectIndexSelectedRow);
    const dispatch = useDispatch();
    let header = ['HPO term', 'Phenotype'];
    let rows = [];
    const [showMore, setShowMore] = useState(false);
    const nGenes = 2;
    const [getOrders, setOrders] = useState(Array(2+nGenes).fill((null))); //null is the state "unsorted", true sorted down, false sorted up
    const phenotypeToShow = 3;
    const informativityLegend = [{ value: 'weak', color: '#ee8373' }, { value: 'fair', color: '#ffc20e' }, { value: 'strong', color: '#5abeba' }];
    
    if(combinations[index].hpoGenesExp !== null && combinations[index].hpoGenesExp.length>0) {
        combinations[index].hpoGenesExp.map((elem) => {
            header = header.concat(elem.gene);
            return null;
        })

        combinations[index].hpoGenesExp[0].hpoList.map((hpo, i) => {
            rows[i] = <tr key={i}>
                <td key={hpo.id}>{hpo.id}</td>
                <td key={hpo.name}>{hpo.name}</td>
                {constructIcCol(i)}
            </tr>;
            return null;
        })

        function constructIcCol(i) {
            return combinations[index].hpoGenesExp.map((hpoGene, j) => {
                return <td key={j}>
                    <div className="bar">
                        <div className="bar-percent" style={{width: `${hpoGene.hpoList[i].ic}%`}}></div>
                    </div>
                </td>
            })
        }
    }

    function changeSortOrder(ind){
        let order = [...getOrders];
        for(let i = 0; i < order.length; i++){
            if(i!==ind) {
                order[i] = null;
            }
            else{
                if(order[i] ===null){
                    order[i] = true;
                }else{
                    order[i] = !order[i];
                }
            }
        }
        setOrders(order);
    }

    function createTableElements(){
        return (combinations[index].hpoGenesExp[0].hpoList.map((hpo, k)=>{
            return{...hpo,
                ic2: combinations[index].hpoGenesExp[1].hpoList[k].ic};
        }));
    }

    function sortPhenos(value) {
        let order = [...getOrders];
        let arraySort;
        let arraySort2;
        let combinationscopy;
        let elemToChange;
        let hpoGenesExp0copy;
        let hpoGenesExp1copy;
        switch (value) {
            case 'HPO term':
                changeSortOrder(0);
                arraySort = [...combinations[index].hpoGenesExp[0].hpoList].sort((a,b) => order[0] ? (a.id > b.id ? 1 : -1) : (a.id < b.id ? 1 : -1));
                arraySort2 = [...combinations[index].hpoGenesExp[1].hpoList].sort((a,b) => order[0] ? (a.id > b.id ? 1 : -1) : (a.id < b.id ? 1 : -1));
                combinationscopy = [...combinations];
                elemToChange = {...combinationscopy[index]};
                hpoGenesExp0copy = {...elemToChange.hpoGenesExp[0]};
                hpoGenesExp1copy = {...elemToChange.hpoGenesExp[1]};
                hpoGenesExp0copy.hpoList = arraySort;
                hpoGenesExp1copy.hpoList = arraySort2;
                elemToChange.hpoGenesExp = [hpoGenesExp0copy, hpoGenesExp1copy];
                combinationscopy[index] = elemToChange;
                dispatch(setOnlyCombinations(combinationscopy));
                break;
            case 'Phenotype':
                changeSortOrder(1);
                arraySort = [...combinations[index].hpoGenesExp[0].hpoList].sort((a,b) =>order[1] ? (a.name > b.name ? 1 : -1) : (a.name < b.name ? 1 : -1));
                arraySort2 = [...combinations[index].hpoGenesExp[1].hpoList].sort((a,b) => order[1] ? (a.name > b.name ? 1 : -1) : (a.name < b.name ? 1 : -1));
                combinationscopy = [...combinations];
                elemToChange = {...combinationscopy[index]};
                hpoGenesExp0copy = {...elemToChange.hpoGenesExp[0]};
                hpoGenesExp1copy = {...elemToChange.hpoGenesExp[1]};
                hpoGenesExp0copy.hpoList = arraySort;
                hpoGenesExp1copy.hpoList = arraySort2;
                elemToChange.hpoGenesExp = [hpoGenesExp0copy, hpoGenesExp1copy];
                combinationscopy[index] = elemToChange;
                dispatch(setOnlyCombinations(combinationscopy));
                break;
            default:
                if(value === combinations[index].combinationMembers[0].gene){
                    changeSortOrder(2);
                    let phenotypeElements = createTableElements();
                    let arraySortBase = [...phenotypeElements].sort((a,b) => {
                        let ord;
                        if(order[2]){
                            ord = (a.ic - b.ic);
                            if(ord === 0){
                                ord = (a.ic2 - b.ic2);
                            }
                        }else{
                            ord = (b.ic - a.ic);
                            if(ord === 0){
                                ord = (b.ic2 - a.ic2);
                            }
                        }
                        return ord;
                    });
                    // console.log(arraySortBase);
                    arraySort = [...combinations[index].hpoGenesExp[0].hpoList].sort((a,b) => arraySortBase.map(e => e.id).indexOf(a.id) - arraySortBase.map(e=>e.id).indexOf(b.id));
                    arraySort2 = [...combinations[index].hpoGenesExp[1].hpoList].sort((a,b) => arraySortBase.map(e => e.id).indexOf(a.id) - arraySortBase.map(e=>e.id).indexOf(b.id));
                    combinationscopy = [...combinations];
                    elemToChange = {...combinationscopy[index]};
                    hpoGenesExp0copy = {...elemToChange.hpoGenesExp[0]};
                    hpoGenesExp1copy = {...elemToChange.hpoGenesExp[1]};
                    hpoGenesExp0copy.hpoList = arraySort;
                    hpoGenesExp1copy.hpoList = arraySort2;
                    elemToChange.hpoGenesExp = [hpoGenesExp0copy, hpoGenesExp1copy];
                    combinationscopy[index] = elemToChange;
                    dispatch(setOnlyCombinations(combinationscopy));
                } else if(value === combinations[index].combinationMembers[1].gene){
                    changeSortOrder(3);
                    let phenotypeElements = createTableElements();
                    let arraySortBase = [...phenotypeElements].sort((a,b) => {
                        let ord;
                        if(order[3]){
                            ord = (a.ic2 - b.ic2);
                            if(ord === 0){
                                ord = (a.ic - b.ic);
                            }
                        }else{
                            ord = (b.ic2 - a.ic2);
                            if(ord === 0){
                                ord = (b.ic - a.ic);
                            }
                        }
                        return ord;
                    });
                    arraySort = [...combinations[index].hpoGenesExp[1].hpoList].sort((a,b) => arraySortBase.map(e => e.id).indexOf(a.id) - arraySortBase.map(e=>e.id).indexOf(b.id));
                    arraySort2 = [...combinations[index].hpoGenesExp[0].hpoList].sort((a,b) => arraySortBase.map(e => e.id).indexOf(a.id) - arraySortBase.map(e=>e.id).indexOf(b.id));
                    combinationscopy = [...combinations];
                    elemToChange = {...combinationscopy[index]};
                    hpoGenesExp0copy = {...elemToChange.hpoGenesExp[1]};
                    hpoGenesExp1copy = {...elemToChange.hpoGenesExp[0]};
                    hpoGenesExp0copy.hpoList = arraySort;
                    hpoGenesExp1copy.hpoList = arraySort2;
                    elemToChange.hpoGenesExp = [hpoGenesExp1copy, hpoGenesExp0copy];
                    combinationscopy[index] = elemToChange;
                    dispatch(setOnlyCombinations(combinationscopy));
                }
                break;
        }
    }

    return (
        <div className="info-panel">
            <span className="info-span" style={{ marginBottom: '1rem' }}><p className="infoTable-label">Phenotype informativity index:</p>
                {combinations[index].variantContribution!== null ? <PointerBar legend={informativityLegend} value={combinations[index].phenotypesInformativityIndex} />: <p className='infoNotAvailable'>Not Available</p>}
            </span>
            { combinations[index].hpoGenesExp !== null && combinations[index].hpoGenesExp.length>0 ?
            <table className="info-table phenotype-info-table">
                <thead>
                    <tr key='header'>
                        {header.map((elem, i) => {
                            return <React.Fragment key={elem}><th key={elem}> <div className='div-header' onClick= {()=> sortPhenos(elem)}><span title={'Sort by ' + elem}>{elem}</span> <FontAwesomeIcon  className='svg-custom' icon={getOrders[i] !== null ? (getOrders[i] ? faSortDown : faSortUp) : faSort} /> </div></th></React.Fragment>
                        })}</tr>
                </thead>
                <tbody>
                    {rows.length <= phenotypeToShow ? rows : showMore === false ? <>{rows.slice(0, phenotypeToShow)}<tr key='show more'><td key='showMore'><p className="showMore" onClick={() => { setShowMore(true) }}>Show more...</p></td></tr></> : rows}
                </tbody>
            </table> : <div className='infoTable-label'>No phenotypes to show.</div>}
        </div>
    )
}

export default PhenotypesInfo;