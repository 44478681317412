import './Phenotype.css';
import DeleteBubble from '../DeleteBubble';
import TooltipPhenotype from './TooltipPhenotype';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

function Phenotype(props) {

    const renderTooltipPhenotype = (e, value) => (
        <Tooltip id="tooltip" {...e}>
            <div className='tool'>
                {value}
            </div>
        </Tooltip>);

    switch (props.value.code) {
        case 0:
            return (
                <div className='phenotype-container'>
                    <div className="buttonValid">
                        <span className="validPhenotype">
                            <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={(e) => renderTooltipPhenotype(e, props.value.name)}>
                                <p className="phenotype">{props.value.hpo_id}</p>
                            </OverlayTrigger>
                            <TooltipPhenotype value={props.value}/>
                        </span>
                    </div>
                    <DeleteBubble type='phenotype' value={props.value} index={props.index} />
                </div>
            );
        case 2:
            return (
                <div className='phenotype-container'>
                    <div className="buttonInvalid">
                        <span className="invalidPhenotype">
                            <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={(e) => renderTooltipPhenotype(e, "This phenotype is not valid")}>
                                <p className="phenotype">{props.value.hpo_id}</p>
                            </OverlayTrigger>
                        </span>
                    </div>
                    <DeleteBubble type='phenotype' value={props.value} index={props.index} />
                </div>
            );
        default:
            break;
    }
}

export default Phenotype;