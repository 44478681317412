import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Country from './Country';
import TooltipPass from './TooltipPass';
import TooltipUser from './TooltipUser';
import Password from './Password';
import { useDispatch, useSelector } from 'react-redux';
import {
  setShowSignUp,
  selectSignUp,
  setShowLogin,
  setShowRegistrationSuccess,
  setTermsOfUse,
  setPrivacyPolicy
} from '../../../Slice/modalVisibilitySlice';
import { selectUserData, setUserData, resetUserData } from '../../../Slice/userDataSlice';
import './Modal.css';
import { useState } from 'react';
import { selectPassword, setPasswordInit } from '../../../Slice/passwordSlice';
import { setErrModalVal, selectErrModalVal, setErrModalValInit } from '../../../Slice/errModalValidationSlice';
import {Url} from '../../../constants/global';
function SignUp() {
  const show = useSelector(selectSignUp);
  const data = useSelector(selectUserData);
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [country1, setCountry1] = useState(''); //lascio per settare la props della select, ma se uso il userDataSlice, tolgo e passo quello
  const password = useSelector(selectPassword);
  const errValidation = useSelector(selectErrModalVal);
  let errSignUp;
  let errPass;

  const handleClose = () => {
    dispatch(setShowSignUp(false));
    setValidated(false);
    dispatch(setPasswordInit());
    setCountry1('');
    dispatch(resetUserData());
    dispatch(setErrModalValInit());
  };

  function returnLogin() {
    handleClose();
    dispatch(setShowLogin(true));
  }

  function termsOfUseClick(){
    dispatch(setTermsOfUse(true));
  }

  function privacyPolicyClick(){
    dispatch(setPrivacyPolicy(true));
  }

  function handleSubmit(event) {
    const form = event.currentTarget;
    setValidated(true);
    event.preventDefault();
    if (form.checkValidity() === false || errPass !== "") {
      event.stopPropagation();
    } else {
      const fetchPromise = fetch(Url + '/oliver/auth/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          firstName: data.firstname,
          lastName: data.lastname,
          username: data.user,
          email: data.email,
          institution: data.aff,
          country: country1,
          password: password.pass
        })
      });

      fetchPromise
        .then((response) => {
          // console.log(response.status);
          if (!response.ok) {
            if (response.status === 409) {
              dispatch(setErrModalVal({ show: true, value: 'Username/email already present' }));
            } else {
              dispatch(setErrModalVal({ show: true, value: 'An error occurred.' }));
            }
            throw new Error(`HTTP error: ${response.status}`);
          } else {
            return response.json();
          }
        })
        .then(() => {
          handleClose();
          dispatch(setShowRegistrationSuccess(true));
        })
        .catch((error) => {
          console.error(`Could not get products: ${error}`);
        });
    }
  }

  function handleChange() {
    dispatch(setErrModalValInit());
  }

  if (errValidation.show) {
    errSignUp = <p className='mb-2 errorMessage'>{errValidation.value}</p>
  } else {
    errSignUp = <></>
  }

  if (password.pass !== password.confirm) {
    errPass = "Passwords do not match.";
  } else {
    errPass = "";
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" className='modal' dialogClassName="modal-90w">
        <Modal.Header>
          <Modal.Title className='title'>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5abeba"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm0 8c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm-6 4c.22-.72 3.31-2 6-2 2.7 0 5.8 1.29 6 2H9zm-3-3v-3h3v-2H6V7H4v3H1v2h3v3z" /></svg>
            Sign Up</Modal.Title>
          <Button onClick={handleClose} id='close'>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z" /><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
          </Button>
        </Modal.Header>
        <Modal.Body className='body'>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Container>
              <Row className="mb-2">
                <label>FIRST NAME</label>
                <input type="text" id='firstname' placeholder="First name" autoFocus='autofocus' required minLength='3' pattern='[A-Za-zÀ-ÖØ-öø-ÿ\s\.]*' onChange={(e) => { dispatch(setUserData({ ...data, firstname: e.target.value })); handleChange() }} />
                <Form.Control.Feedback type="invalid" className='invalid'>Please enter a valid name.</Form.Control.Feedback></Row>
              <Row className="mb-1">
                <label>LAST NAME</label>
                <input type="text" id='lastname' placeholder="Last name" required minLength={3} pattern="[A-Za-zÀ-ÖØ-öø-ÿ\s\.']*" onChange={(e) => { dispatch(setUserData({ ...data, lastname: e.target.value })); handleChange() }} />
                <Form.Control.Feedback type="invalid" className='invalid'>Please enter a valid last name.</Form.Control.Feedback>
              </Row>
              <Row className="mb-4"><Col><label>USERNAME</label></Col>
                <Col className='info'>
                  <TooltipUser /></Col>
                <input type="text" id='user' placeholder="Eg:Name.surname" required pattern='[A-Za-z0-9À-ÖØ-öø-ÿ\.]*' maxLength={32} onChange={(e) => { dispatch(setUserData({ ...data, user: e.target.value })); handleChange() }} />
                <Form.Control.Feedback type="invalid" className='invalid'>Please enter a valid username.</Form.Control.Feedback>
              </Row>
              <Row className="mb-4">
                <label>EMAIL ADDRESS</label>
                <input type="email" id='email' placeholder="name@example.com" required onChange={(e) => { dispatch(setUserData({ ...data, email: e.target.value })); handleChange() }} />
                <Form.Control.Feedback type="invalid" className='invalid'>Please enter a valid email.</Form.Control.Feedback>
              </Row>
              <Row className="mb-2">
                <label>AFFILIATION</label>
                <input type="text" id='aff' placeholder="Affiliation" required pattern='[A-zÀ-ú0-9\s\-_\.]*[A-zÀ-ú]{3,}[A-zÀ-ú0-9\s\-_\.]*' minLength={3} onChange={(e) => { dispatch(setUserData({ ...data, aff: e.target.value })); handleChange() }} />
                <Form.Control.Feedback type="invalid" className='invalid'>Please enter a valid affiliation.</Form.Control.Feedback>
              </Row>
              <Row className="mb-4">
                <label>COUNTRY</label>
                <Country value='required' mystate={[country1, setCountry1]} />
                <Form.Control.Feedback type="invalid" className='invalid'>Please select a country.</Form.Control.Feedback>
              </Row>
              <Row className="mb-2"><Col>
                <label>PASSWORD</label></Col>
                <Col className='info'>
                  <TooltipPass />
                </Col>
                <Password value='pass'></Password>
                <Form.Control.Feedback type="invalid" className='invalid'>Please enter a valid password.</Form.Control.Feedback>
              </Row>
              <Row className="mb-2">
                <label>CONFIRM PASSWORD</label>
                <Password value='confirm'></Password>
                <span className='invalid-password'>{validated ? errPass : null}</span>
              </Row>
              <Row><Col className="mb-2">
                <input type="checkbox" name="check"  id="checkbox_id1" required onChange={handleChange} />
                <label htmlFor="checkbox_id1" className='check'>I agree to the <span onClick={(e) => {e.preventDefault(); termsOfUseClick();}}>Terms of Use</span></label>
                <Form.Control.Feedback type="invalid" className='invalid'>Please accept our Term of Use.</Form.Control.Feedback>
              </Col></Row>
              <Row><Col className="mb-2">
                <input type="checkbox" name="check2" id="checkbox_id2" required onChange={handleChange} />
                <label htmlFor="checkbox_id2" className='check'>I agree to the <span onClick={(e) => {e.preventDefault(); privacyPolicyClick();}}>Privacy Policy</span></label>
                <Form.Control.Feedback type="invalid" className='invalid'>Please accept our Privacy Policy.</Form.Control.Feedback>
              </Col></Row>
              <Row><Col className="mb-2">
                <input type="checkbox" id="checkbox_id3" name="check3"/>
                <label htmlFor="checkbox_id3" className='check'>Subscribe to our Newsletters</label>
                {/*<Form.Control.Feedback type="invalid" className='invalid'>Please accept our Privacy Policy.</Form.Control.Feedback>*/}
              </Col></Row>
              <Row><Col md={{ offset: 3 }}><Button className="mb-2" type='submit' id='signup'>
                SIGN UP
              </Button></Col></Row>
              <Row>{errSignUp}</Row>
              <Row className='align-right'>
                <label className="mb-2 lab">Do you have an account? <span onClick={returnLogin} className='link'>LOGIN</span></label>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SignUp;