import { Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { selectCiteUs, setCiteUs } from '../../../../Slice/modalVisibilitySlice';
import '../Modal.css';
import './CiteUs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faQuoteRight} from "@fortawesome/free-solid-svg-icons";

function CiteUs() {
    const show = useSelector(selectCiteUs);
    const dispatch= useDispatch();

    const handleClose = () => {
        dispatch(setCiteUs(false));
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" className='modal' dialogClassName='modal-cite'>
                <Modal.Header>
                    <Modal.Title className='title'>
                    <FontAwesomeIcon icon={faQuoteRight} style={{verticalAlign: 'middle'}}/>
                        Cite Us</Modal.Title>
                    <Button onClick={handleClose} id='close'>
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z" /><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>

                            <h5>About us</h5>
                            <p>enGenome is a bioinformatics company passionate about developing advanced solutions for genomic interpretation. Our suite of products and services has been designed to offer a transformative approach to the diagnosis of genetic diseases. <br/>
                                We provide solutions to interpret sequencing data and generate accurate omics-based reports to physicians, aiming to enhance the diagnosis and treatment processes for patients affected by rare disorders. For more detailed information, visit enGenome's official website at <a style={{textDecoration:"none"}} href='https://www.engenome.com/' target='_blank' rel='noreferrer'>www.engenome.com</a>.
                            </p>
                            <hr/>
                            <h5>Cite Oliver</h5>
                            <p>If you find the Oliver Platform useful in your research, please acknowledge our contribution by citing our publication:</p>
                            <p>Digenic variant interpretation with hypothesis-driven explainable AI <br/>
                               Federica De Paoli, Giovanna Nicora, Silvia Berardelli, Andrea Maria Gazzo, Riccardo Bellazzi, Paolo Magni, Ettore Rizzo, Ivan Limongelli, Susanna Zucca <br/>
                               bioRxiv 2023.10.02.560464; doi: <a href='https://doi.org/10.1101/2023.10.02.560464' target='_blank' rel="noreferrer" style={{textDecoration:"none"}}>https://doi.org/10.1101/2023.10.02.560464</a></p>
                            <p>Oliver has been supported by EU project 190164416.</p>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CiteUs;