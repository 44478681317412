import {Url} from '../../../constants/global';

//validation genomic
export const validateGenomicSearch = function (strings_array_in) {
    strings_array_in = strings_array_in.toUpperCase().split(' ');
    let genomic = [];
    if (strings_array_in.length === 4) {
        if (strings_array_in[0] && /^([1]\d|[1-9]|2[0-2]|[xX]|[yY]|(MT|mt))$/.test(strings_array_in[0])) { // chrom 1-22 X Y MT
            if (strings_array_in[1] && /^[1-9]\d*$/.test(strings_array_in[1])) { // positive integer position
                if (!(/^[-]$/.test(strings_array_in[2]) && /^[-]$/.test(strings_array_in[3])))
                    if (strings_array_in[2] && strings_array_in[3] && /^[acgtACGT-]{0,50}$/.test(strings_array_in[2]) && /^[acgtACGT-]{0,50}$/.test(strings_array_in[3])) { // ref alt combinazione di acgt-
                        genomic[0] = true;
                        genomic[1] = strings_array_in; //string to send
                    }
            }
        }
    }
    return genomic;
};

//get genomic variants
async function getGenomic(strings_array_in, assembly) {
    try {
        const response = await fetch(Url + '/oliver/combination/variant-search-genomic?' + new URLSearchParams({ assembly: assembly, chrom: strings_array_in[0], start: parseInt(strings_array_in[1]), ref: strings_array_in[2], alt: strings_array_in[3] }), {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
            throw new Error(`HTTP error: ${response.status}`);
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(`Could not get products: ${error}`);
    };
}

//validation HGVS
export const validateHgvsSearch = async function (strings_array_in) {
    let hgvs = [];
    let str = strings_array_in.split(':');
    str[0] = str[0].toUpperCase();
    strings_array_in = str[0] + ':' + str[1];

    const substitution_change = /[cC]\.(\d+|\*\d+|-\d+)([+-]\d+)?([GCTAgcta])?>([GCTAgcta])/;
    const substitution_no_change = /[cC]\.(\d+|\*\d+|-\d+)([+-]\d+)?([GCTAgcta])?=/;
    const indel = /[cC]\.(\d+|\*\d+|-\d+)([+-]\d+)?(?:_(\d+|\*\d+|-\d+)([+-]\d+)?)?([GCTAgcta]+)?delins([GCTAgcta]+)/;
    const deletion = /[cC]\.(\d+|\*\d+|-\d+)([+-]\d+)?(?:_(\d+|\*\d+|-\d+)([+-]\d+)?)?del([GCTAgcta]+)?/;
    const insertion_simple = /[cC]\.(\d+|\*\d+|-\d+)([+-]\d+)?_(\d+|\*\d+|-\d+)([+-]\d+)?ins([GCTAgcta]+)/;
    const inversion = /[cC]\.(\d+|\*\d+|-\d+)([+-]\d+)?_(\d+|\*\d+|-\d+)([+-]\d+)?inv/;
    const duplication_simple = /[cC]\.(\d+|\*\d+|-\d+)([+-]\d+)?(?:_(\d+|\*\d+|-\d+)([+-]\d+)?)?dup([GCTAgcta]+)?/;

    if (str.length === 2 && /^[\S]*$/.test(strings_array_in)) {// gene:hgvs senza spazi
        if (strings_array_in && /^(NM_|NP_)[\d.]+$/.test(str[0])) { //inizia con NM_ o NP_
            if (str[1].charAt(0).toLowerCase() === 'c') {
                if (substitution_change.test(str[1]) || substitution_no_change.test(str[1]) || indel.test(str[1]) || deletion.test(str[1]) || insertion_simple.test(str[1]) || inversion.test(str[1]) || duplication_simple.test(str[1])) {
                    hgvs[0] = true;
                    hgvs[1] = str;
                }
            } else if (str[1].charAt(0).toLowerCase() === 'p') { // ToDO
                hgvs[0] = true;
                hgvs[1] = str;
            }
        } else if (strings_array_in && /^[a-zA-Z0-9-]+$/.test(str[0])) { //inizia col nome del gene
            if (str[1].charAt(0).toLowerCase() === 'c') {
                if (substitution_change.test(str[1]) || substitution_no_change.test(str[1]) || indel.test(str[1]) || deletion.test(str[1]) || insertion_simple.test(str[1]) || inversion.test(str[1]) || duplication_simple.test(str[1])) {
                    hgvs[0] = true;
                    hgvs[1] = str;
                }
            } else if (str[1].charAt(0).toLowerCase() === 'p') { // ToDO
                hgvs[0] = true;
                hgvs[1] = str;
            }
            if (hgvs[0]) {
                hgvs[2] = await validateGeneHgvs(str[0]);
                if (hgvs[2].code === 2) {
                    hgvs[0] = false;
                } else if (hgvs[2].code === 1) {
                    hgvs[1][0] = hgvs[2].gene; // da rivedere, magari è hgvs[1]
                }
            }
        }
    }
    return hgvs;
}

//validate gene
async function validateGeneHgvs(gene) {
    try {
        const response = await fetch(Url + '/oliver/genes/validate-gene?' + new URLSearchParams({ name: gene }), {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
            throw new Error(`HTTP error: ${response.status}`);
        } else {
            const data = await response.json();
            return { code: data.status_code, gene: data.name };
        }
    } catch (error) {
        console.error(`Could not get products: ${error}`);
    };
}

//get HGVS
async function getHgvs(strings_array_in) {
    try {
        const response = await fetch(Url + '/oliver/combination/variant-search-hgvs?' + new URLSearchParams({ transcriptIdOrGene: strings_array_in[0], hgvs: strings_array_in[1] }), {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

        if (!response.ok) {
            throw new Error(`HTTP error: ${response.status}`);
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(`Could not get products: ${error}`);
    };
}

//function that validates and if the condition is true search
export const variantSearch = async function (searchText, assembly) {
    const genomic = validateGenomicSearch(searchText);
    const hgvs = await validateHgvsSearch(searchText);
    //result[0]= isValid (true/false)   result[1]=get response  result[2]={code:int, gene:name} <=only if there is hgvs search with gene name
    let result = [];
    if (genomic[0]) {
        result[0] = true;
        result[1] = await getGenomic(genomic[1], assembly);
        return result;
    } else if (hgvs[0]) {
        result[0] = true;
        result[1] = await getHgvs(hgvs[1]);
        if (hgvs.length > 2) { //case of hgvs search with valid gene name
            result[2] = hgvs[2];
        }
        return result;
    } else {
        result[0] = false;
        if (hgvs.length > 2) { //case of hgvs search with invalid gene name
            result[1] = []; //combination not exist because it's not search
            result[2] = hgvs[2];
        }
        return result;
    }
}