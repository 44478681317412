function PointerBar(props){
    let pointBar=[];

    for (let i = 0; i < props.legend.length; i++) {
        pointBar[i] = <div className="point-bar" title={props.value} key={i}>
            {props.legend.map((info,j)=>{
                if(info.value===props.value && i<=j){
                    return <div className="point-bar-percent" style={{backgroundColor:props.legend[j].color}} key={info+j}></div>
                    } else {
                        return null;
                    }
                }
            )}
        </div>
    }

    return(
        <>{pointBar}</>
    )
}

export default PointerBar;