import {useState} from "react";

function PageHelperEntry({props:{title, content}}) {
    const [open, setOpen] = useState(true);
    const [rotate, setRotate] = useState(null);

    return ( <>
        <div className='subtitleHelpCenter' onClick={() => {setOpen(!open);
            if(rotate !==null) {
                setRotate(!rotate);
            }else{
                setRotate(true);
            }
        }} > {title}
            <svg className={rotate !== null ? (rotate ? 'svgClickOpen' : ''): ''} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#344d66"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87" /><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" /></svg>
        </div>
        <div className={open ? 'show' : 'notshow'}>
            {content}
        </div></>);
}

export default PageHelperEntry;