import { Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { selectLogin, setShowLogin, setShowSignUp, setMailForgotSent } from '../../../Slice/modalVisibilitySlice';
import { setPasswordInit, selectPassword } from '../../../Slice/passwordSlice';
import './Modal.css';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { setLog } from '../../../Slice/logSlice';
import { setUserData, selectUserData } from '../../../Slice/userDataSlice';
import { setBearer } from '../../../Slice/bearerSlice';
import { setErrModalVal, selectErrModalVal, setErrModalValInit } from '../../../Slice/errModalValidationSlice';
import Password from './Password';
import jwt_decode from "jwt-decode";
import {Url} from '../../../constants/global.js';

function Login() {
  const show = useSelector(selectLogin);
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [forgot, setForgot] = useState(false);
  const password = useSelector(selectPassword);
  const [email, setEmail] = useState('');
  const [emailForgot, setEmailForgot] = useState('');
  const errValidation = useSelector(selectErrModalVal);
  const userData = useSelector(selectUserData);
  const navigate = useNavigate();
  const resetInputRef = useRef(null);
  let requestPass;
  let [resetPassMessage, setResetPassMessage] = useState({ show: false, value: '' });
  let errLogin;

  const handleClose = () => {
    dispatch(setShowLogin(false));
    setValidated(false);
    setForgot(false);
    setEmail('');
    setEmailForgot('');
    dispatch(setErrModalValInit());
    dispatch(setPasswordInit());
    setResetPassMessage({ show: false, value: '' });
    navigate("/");
  };

  function showSign() {
    handleClose();
    dispatch(setShowSignUp(true));
  }

  function handleSubmit(event) {
    event.preventDefault();
    if(resetInputRef.current === document.activeElement){
      resetPass();
    } else {
      const form = event.currentTarget;
      setValidated(true);
      if (form.checkValidity() === false) {
        event.stopPropagation();
      } else {
        const fetchPromise = fetch(Url +'/oliver/auth/login', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ emailOrUsername: email, password: password.old })
        });
        
        fetchPromise
        .then((response) => {
          if (!response.ok) {
            if (response.status === 409) {
              dispatch(setErrModalVal({ show: true, value: 'Invalid credentials!' }));
            } else {
              dispatch(setErrModalVal({ show: true, value: 'An error occurred.' }));
            }
            throw new Error(`HTTP error: ${response.status}`);
          } else {
            return response.json();
          }
        })
        .then((data) => {
          dispatch(setBearer(data.jwtToken));
          dispatch(setUserData({ ...userData, user: jwt_decode(data.jwtToken).username }));
          dispatch(setLog(true));
          handleClose();
        })
        .catch((error) => {
          console.error(`Could not get products: ${error}`);
        });
      }
    }
  }

  function resetPass() {
    if (emailForgot !== '') {
      const fetchPromise = fetch(Url + '/oliver/forgot-password?' + new URLSearchParams({ email: emailForgot }), {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      });
      fetchPromise
        .then((response) => {
          if (!response.ok) {
            setResetPassMessage({ show: true, value: 'An error occurred.' });
            throw new Error(`HTTP error: ${response.status}`);
          } else {
            return response;
          }
        })
        .then(() => {
          handleClose();
          dispatch(setMailForgotSent(true));
        })
        .catch((error) => {
          console.error(`Could not get products: ${error}`);
        });
    }
  }

  if (forgot) {
    requestPass = <>
      <div className='det'>Enter your details below</div>
      <input type="email" id='email' className='mb-1' placeholder="Email" autoFocus='autofocus' onChange={(e) => { setEmailForgot(e.target.value); dispatch(setErrModalValInit()) }} ref={resetInputRef}/>
      <Button className="mb-3" type='button' id='reset' onClick={resetPass}>
        RESET PASSWORD
      </Button>
      {resetPassMessage.show === true ? <p className='mb-2 errorMessage'>{resetPassMessage.value}</p> : null}
    </>;
  } else {
    requestPass = <></>;
  }

  if (errValidation.show) {
    errLogin = <p className='mb-2 errorMessage'>{errValidation.value}</p>
  } else {
    errLogin = <></>
  }

  if(window.location.pathname==='/firstLogin' && show===false){
    dispatch(setBearer(''));
    dispatch(setLog(false));
    dispatch(setShowLogin(true));
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" className='modal'>
        <Modal.Header>
          <Modal.Title className='title'>
            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#5abeba"><g><rect fill="none" height="24" width="24" /></g><g><g><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.35 18.5C8.66 17.56 10.26 17 12 17s3.34.56 4.65 1.5c-1.31.94-2.91 1.5-4.65 1.5s-3.34-.56-4.65-1.5zm10.79-1.38C16.45 15.8 14.32 15 12 15s-4.45.8-6.14 2.12C4.7 15.73 4 13.95 4 12c0-4.42 3.58-8 8-8s8 3.58 8 8c0 1.95-.7 3.73-1.86 5.12z" /><path d="M12 6c-1.93 0-3.5 1.57-3.5 3.5S10.07 13 12 13s3.5-1.57 3.5-3.5S13.93 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z" /></g></g></svg>
            Login</Modal.Title>
          <Button onClick={handleClose} id='close'>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z" /><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Container>
              <Form.Group className="mb-3" >
                <Row>
                  <label>EMAIL/USERNAME</label>
                  <input type="text" id='email' placeholder="name@example.com" autoFocus='autofocus' required onChange={(e) => { setEmail(e.target.value); dispatch(setErrModalValInit()) }} />
                  <Form.Control.Feedback type="invalid" className='invalid'>Please enter your email or your username.</Form.Control.Feedback>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <label>PASSWORD</label>
                  <Password />
                  <Form.Control.Feedback type="invalid" className='invalid'>Please enter your password.</Form.Control.Feedback>
                </Row>
              </Form.Group>
              <Container>
                <Row><Button className="mb-3" type='submit' id='signin'>
                  SIGN IN
                </Button></Row>
                <Row>
                  {errLogin}
                </Row>
                <Row className='center'>
                  <span className="mb-3 link" onClick={() => setForgot(!forgot)}>Forgot your password?</span>
                  {requestPass}
                  <Form.Label className='lab'>Do not have an account? <span onClick={showSign} className='link'>Sign Up</span></Form.Label>
                </Row>
              </Container>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Login;