import '../Search.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectSearch, setSearch, selectUpdateFocus, selectSearching, startSearching } from '../../../Slice/searchBarSlice';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { geneValidation, getGeneCombination } from '../validationSearchFunction/geneValidation';
import { setGene, selectGene, selectCount } from '../../../Slice/geneSlice';
import GeneList from './GeneList';
import { setTimer } from '../../../Slice/timerSlice';
import { setPlaceholder, selectPlaceholder } from '../../../Slice/geneSlice';
import { selectShowResults, setCombinations, setShowResults } from '../../../Slice/resultsSlice';
import { selectBearer } from '../../../Slice/bearerSlice';
import { setShowLogin } from '../../../Slice/modalVisibilitySlice';
import { setErrModalVal } from '../../../Slice/errModalValidationSlice';
import {setLoader} from "../../../Slice/loaderSlice";

function GeneSearch() {
    const searchText = useSelector(selectSearch);
    const dispatch = useDispatch();
    const ref = useRef(null);
    const inputRef = useRef(null);
    const [width, setWidth] = useState(0);
    const placeholder = useSelector(selectPlaceholder);
    const geneInfo = useSelector(selectGene);
    const timerRef = useRef();
    const nGene = 2; //max number of gene that we can search
    const [disabledInput, setDisabled] = useState(false);
    const [isPaste, setPaste] = useState(false);
    const token = useSelector(selectBearer);
    const showRes = useSelector(selectShowResults);
    const counters = useSelector(selectCount);
    const exampleFocus = useSelector(selectUpdateFocus);
    const searching = useSelector(selectSearching);

    useLayoutEffect(() => {
        setWidth(ref.current.offsetWidth);
        if (ref.current.offsetWidth === 16) {
            dispatch(setPlaceholder("Search by gene"));
        }
        if (geneInfo.length >= nGene || searching) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
        if (!searching){
            dispatch(setLoader(false));
        }
    }, [geneInfo, searching]);

    useEffect(()=>{
        inputRef.current.focus();
    }, [exampleFocus, counters]);

    const timerFunction = () => {
        timerRef.current = setTimeout(() => {
            validateGene(searchText);
        }, 5000)
        dispatch(setTimer(timerRef.current));
    }

    const clearTimerFunction = () => {
        clearTimeout(timerRef.current);
    }

    async function handlechange(e) {
        dispatch(setSearch(e.target.value));
        if (isPaste) {
            await validateGene(e.target.value);
            inputRef.current.focus();
        }
    }

    function keyup(e) {
        if (!isPaste) {
            clearTimerFunction();
            if (e.key !== 'Enter' && e.key !== ' ') {
                timerFunction();
            }
        }
    }

    async function keydown(e) {
        clearTimerFunction();
        setPaste(false);
        if (e.key === 'Enter') {
            let geneList = []; //list of valid genes to search for
            dispatch(startSearching(true));
            geneInfo.map((gene) => {
                if (gene.code !== 2) {
                    geneList.push(gene.gene);
                }
                return null;
            });
            if (searchText.replace(/\s+/g, '') !== '') { //search without validate first
                const gene = await validateGene(searchText);
                for (const element of gene) {
                    if (element.code !== 2) {
                        geneList.push(element.gene);
                    }
                }
            }
            //entro solo se mando dei geni, se invio una stringa vuota o dei geni invalid non faccio call al back-end
            if (geneList.length !== 0) {
                if (token !== '') {
                    dispatch(setLoader(true));
                    const combinations = await getGeneCombination(geneList, token);
                    if (combinations !== null) {
                        dispatch(setShowResults(true));
                        dispatch(setCombinations(combinations));
                    } else {
                        dispatch(setShowResults(!showRes)); //to force rerender
                    }
                } else {
                    e.preventDefault();
                    dispatch(setShowLogin(true));
                    dispatch(setErrModalVal({ show: true, value: 'In order to search you need to login.' }));
                }
            }
            dispatch(startSearching(false));
        } else if (e.key === ' ' && searchText.replace(/\s+/g, '') !== '') {
            validateGene(searchText);
        }
    }

    function paste() {
        clearTimerFunction();
        setPaste(true);
    }

    //valido il gene
    async function validateGene(searchText) {
        setDisabled(true);
        dispatch(setLoader(true));
        const validate = await geneValidation(searchText, nGene, geneInfo.length);
        //0 valid, 1 synonym, 2 invalid
        for (let i = 0; i < validate.length; i++) {
            switch (validate[i].code) {
                case 0:
                    dispatch(setGene(validate[i]));
                    dispatch(setPlaceholder(""));
                    dispatch(setSearch(""));
                    break;
                case 1:
                    dispatch(setGene(validate[i]));
                    dispatch(setPlaceholder(""));
                    dispatch(setSearch(""));
                    break;
                case 2:
                    dispatch(setGene(validate[i]));
                    dispatch(setPlaceholder(""));
                    dispatch(setSearch(""));
                    break;
                default:
                    break;
            }
        }
        return validate;
    }

    return (
        <span className='searchGene-container' style={{ position: 'relative' }}>
            <span style={{ position: 'absolute' }} className="geneRef" ref={ref}><GeneList /></span>
            <input type="text" name="input-search" className='inputSearchGene' disabled={disabledInput} placeholder={placeholder} value={searchText} onChange={handlechange} style={{ paddingLeft: width }} onKeyUp={keyup} onKeyDown={keydown} onPaste={paste} ref={inputRef} autoComplete="off"/>
        </span>

    );

}

export default GeneSearch;