import multi from '../../../Images/multi.png';
import { selectIndexSelectedRow } from '../../../Slice/resultsSlice';
import { useSelector } from 'react-redux';

function Zygosity(props){
    const showResultInfoIndex = useSelector(selectIndexSelectedRow);

    return(
       <>{props.variant === 'het' ? <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <rect className='results-icon-blue' stroke="#344D66" fill="#344D66" x="6" y="9" width="4" height="12" rx="2" style={showResultInfoIndex === props.index ? { fill: '#ffffff', stroke: '#ffffff' } : null} />
            <rect className='results-icon-blue' stroke="#344D66" fill="#344D66" x="6" y="3" width="4" height="6" rx="2" style={showResultInfoIndex === props.index ? { fill: '#ffffff', stroke: '#ffffff' } : null} />
            <rect className='results-icon-white' stroke="#344D66" x="14" y="9" width="4" height="12" rx="2" style={showResultInfoIndex === props.index ? { stroke: '#ffffff' } : null} />
            <rect className='results-icon-white' stroke="#344D66" x="14" y="3" width="4" height="6" rx="2" style={showResultInfoIndex === props.index ? { stroke: '#ffffff' } : null} />
        </g>
    </svg> :
    props.variant === 'hom' ? <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <rect className='results-icon-blue' stroke="#344D66" fill="#344D66" x="6" y="9" width="4" height="12" rx="2" style={showResultInfoIndex === props.index ? { fill: '#ffffff', stroke: '#ffffff' } : null} />
                <rect className='results-icon-blue' stroke="#344D66" fill="#344D66" x="6" y="3" width="4" height="6" rx="2" style={showResultInfoIndex === props.index ? { fill: '#ffffff', stroke: '#ffffff' } : null} />
                <rect className='results-icon-blue' stroke="#344D66" fill="#344D66" x="14" y="9" width="4" height="12" rx="2" style={showResultInfoIndex === props.index ? { fill: '#ffffff', stroke: '#ffffff' } : null} />
                <rect className='results-icon-blue' stroke="#344D66" fill="#344D66" x="14" y="3" width="4" height="6" rx="2" style={showResultInfoIndex === props.index ? { fill: '#ffffff', stroke: '#ffffff' } : null} />
            </g>
        </svg> :
            <img className='zygosity' src={multi} title='Multiallelic' alt='Multiallelic' />}
    </> 
    );   
}

export default Zygosity;