import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { selectResetPassword, setResetPassword } from '../../../Slice/modalVisibilitySlice';
import { setPasswordInit, selectPassword } from '../../../Slice/passwordSlice';
import './Modal.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setErrModalVal, selectErrModalVal, setErrModalValInit } from '../../../Slice/errModalValidationSlice';
import Password from './Password';
import TooltipPass from './TooltipPass';
import {Url} from '../../../constants/global';


function ResetPassword() {
  const show = useSelector(selectResetPassword);
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const password = useSelector(selectPassword);
  const errValidation = useSelector(selectErrModalVal);
  const navigate = useNavigate();
  let errPass;
  let errReset;
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  dispatch(setResetPassword(true));

  const handleClose = () => {
    dispatch(setResetPassword(false));
    setValidated(false);
    dispatch(setPasswordInit());
    dispatch(setErrModalValInit());
    navigate("/");
  };

  function handleSubmit(event) {
    const form = event.currentTarget;
    setValidated(true);
    event.preventDefault();
    if (form.checkValidity() === false || password.pass !== password.confirm) {
      event.stopPropagation();
    } else {
      const fetchPromise = fetch(Url + '/oliver/save-reset-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ newPassword: password.pass, resetToken: token })
      });

      fetchPromise
        .then((response) => {
          if (!response.ok) {
            if (response.status === 409) {
              dispatch(setErrModalVal({ show: true, value: 'An error occurred.' }));
            }
            throw new Error(`HTTP error: ${response.status}`);
          } else {
            return response;
          }
        })
        .then(() => {
          handleClose();
        })
        .catch((error) => {
          console.error(`Could not get products: ${error}`);
        });
    }
  }

  if (password.pass !== password.confirm) {
    errPass = "Passwords do not match.";
  } else {
    errPass = "";
  }

  if (errValidation.show) {
    errReset = <p className='mb-2 errorMessage'>{errValidation.value}</p>
  } else {
    errReset = <></>
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" className='modal'>
        <Modal.Header>
          <Modal.Title className='title'>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path opacity=".87" d="M0 0h24v24H0z" /><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z" fill="#5ABEBA" /></g></svg>
            Reset password</Modal.Title>
          <Button onClick={handleClose} id='close'>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z" /><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Container>
              <Form.Group className="mb-3" >
                <Row className="mb-2"><Col>
                  <label>PASSWORD</label></Col>
                  <Col className='info'>
                    <TooltipPass />
                  </Col>
                  <Password value='pass'></Password>
                  <Form.Control.Feedback type="invalid" className='invalid-password'>Please enter a valid password.</Form.Control.Feedback>
                </Row>
                <Row className="mb-2">
                  <label id='confirm'>CONFIRM PASSWORD</label>
                  <Password value='confirm'></Password>
                  <span className='invalid-password'>{validated ? errPass : null}</span>
                </Row>
              </Form.Group>
              <Container>
                <Row><Button className="mb-3" type='submit' id='signin'>
                  CONFIRM NEW PASSWORD
                </Button></Row>
                <Row>
                  {errReset}
                </Row>
              </Container>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ResetPassword;