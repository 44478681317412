import { selectCombinations, selectIndexSelectedRow } from "../../../../Slice/resultsSlice";
import { useSelector } from "react-redux";
import Combination from "./Combination";
import '../resultsTable.css';
import Accordion from 'react-bootstrap/Accordion';
import '../accordionResult.css';

function DigenicCombinations() {
    const combinationsList = [];
    const combinations = useSelector(selectCombinations);
    const index = useSelector(selectIndexSelectedRow);
    const colHeader = [{ class: 'gene-col', name: 'Symbol' }, { class: 'zygosity-col', name: 'Zyg' }, { class: 'hgvs-col', name: 'Variant' }];
    let cols = [];
    let colInd = 0;

    for (let i = 0; i < 2; i++) {
        cols[i] = colHeader.map((col) => {
            return <th key={colInd++} className={col.class}>{col.name}</th>
        })
    }

    if (combinations.length !== 0) {
        combinations.map((comb, i) => {
            if (comb.combinationMembers.length === 2) {
                combinationsList.push(<Combination value={comb} key={i} index={i} list={combinationsList} />);
            }
            return null;
        });
    }

    return (
        <div className="digenic-table">
            <table className='digenic-table-header first-header'>
                <thead>
                    <tr className='digenic-table-header-row first-header'>
                        <th key={colInd++} colSpan={3} className='gene-header'> Gene 1</th>
                        <th key={colInd++} colSpan={3} className='gene-header'> Gene 2</th>
                        <th key={colInd++} colSpan={3} className='comb-header'> Combination info</th>
                    </tr>
                </thead>
            </table>
            <table className='digenic-table-header second-header'>
                <thead>
                    <tr className='digenic-table-header-row second-header'>
                        {cols}
                        <th key={colInd++} colSpan={2} className='mechanism-header'>Mechanism</th>
                        <th key={colInd++} className='hpo-col'>Phenotypes</th>
                    </tr>
                </thead>
            </table>
            <Accordion defaultActiveKey="0" activeKey={index}>{combinationsList}</Accordion>
        </div>
    );

}

export default DigenicCombinations;