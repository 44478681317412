import { Row, Col } from 'react-bootstrap';
import { selectUserData } from '../../../../Slice/userDataSlice';
import Form from 'react-bootstrap/Form';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { selectMessageProfileVal, setMessageProfileVal } from '../../../../Slice/messageProfileValidationSlice';
import { selectBearer } from '../../../../Slice/bearerSlice';
import { useState } from 'react';
import {Url} from '../../../../constants/global';

function ChangeEmail(props) {
  let messageEmail;
  const data = useSelector(selectUserData);
  const messageValidation = useSelector(selectMessageProfileVal);
  const [open, setOpen] = useState(false);
  const logToken = useSelector(selectBearer);
  const dispatch = useDispatch();

  function reset() {
    props.val[1]({ ...props.val[0], email: false });
    props.edit[1]({ ...props.edit[0], email: '' });
  }

  function handleSubmit(event, id) {
    const form = event.currentTarget;
    event.preventDefault();
    props.val[1]({ ...props.val[0], email: true });
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (!((props.edit[0].email === '' || data.email === props.edit[0].email))) {
        postEmail();
      }
    }
  }

  //function to send data
  function postEmail() {
    const fetchPromise = fetch(Url + '/oliver/users/change-email', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${logToken}` },
      body: JSON.stringify({
        newEmail: props.edit[0].email
      })
    });
    fetchPromise
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`); //da vedere se deve succedere altro
        } else {
          dispatch(setMessageProfileVal(messageValidation.map((err) => {
            if (err.id === 'email') {
              return { ...err, show: true, value: 'A change mail link request has been sent to this address', type: 'success' };
            } else {
              return { ...err };
            }
          })));
          return response;
        }
      })
      .then((data1) => {
        //da vedere cosa fare, perché finché non clicca sul link la mail non va aggiornata nei miei UserData
      })
      .catch((error) => {
        console.error(`Could not get products: ${error}`);
      });
  }

  messageValidation.map((message) => {
    if (message.id === 'email' && message.show === true) {
      if (message.type === 'success') {
        messageEmail = <p className='mb-2 successMessage'>
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5abeba"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" /></svg>
          {message.value}</p>;
      }
    } return null;
  });

  return (
    <Form noValidate validated={props.val[0].email} onSubmit={(e) => { handleSubmit(e, 'email') }}><Row>
      <Form.Label className='profile-label' onClick={() => { setOpen(!open) }}>Change email
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#344d66"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87" /><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" /></svg>
      </Form.Label>
      {open ?
        <span className='sez'>
          <Row className='data mb-2'>
            <Col><label>EMAIL ADDRESS</label></Col>
            <Col><input type="email" id='email' placeholder={data.email} value={props.edit[0].email} onChange={(e) => { props.edit[1]({ ...props.edit[0], email: e.target.value }); props.handleChange('email') }} />
              <Form.Control.Feedback type="invalid" className='invalid'>Please enter your email.</Form.Control.Feedback></Col>
          </Row>
          <Row><Col className='no-wrap saveXcol'><Button className="mb-2 save" type='submit'>SAVE</Button>
            <Button className="mb-2 cancel" type='reset' onClick={() => reset()}>X</Button></Col>
            <Col> {messageValidation[2].show ? messageEmail : null}</Col>
          </Row>
        </span> : null}
    </Row></Form>
  );
}

export default ChangeEmail;